import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Branding from '@core/models/Branding';
import Deal from '@core/models/Deal';

import { Breakable } from '@components/dmp';

const DealBranding = ({ branding, deal }) => {
  const { legalName, address, logo, letterhead } = branding;
  let dealProp = deal;

  if (!legalName && !address && !logo) return null;

  if (deal.template) {
    dealProp = deal.template;
  }

  const style = _.merge({}, deal.style.type.Letterhead.css, {
    marginBottom: deal.style.layout.Letterhead.web.bottom,
  });

  return (
    <div className="deal-branding" style={style} data-cy="deal-branding">
      {dealProp.letterheadType !== 'customized' ? (
        <>
          {logo && (
            <div className="org-logo">
              <img alt="Deal Branding logo" src={logo} />
            </div>
          )}
          <div className="org-meta">
            {legalName && (
              <span className="org-legalname" data-cy="org-legalname">
                {legalName}
              </span>
            )}
            {address && (
              <Breakable className="org-address" data-cy="org-address">
                {address}
              </Breakable>
            )}
          </div>
        </>
      ) : letterhead ? (
        <div className="org-letterhead" data-cy="org-letterhead">
          <img alt="Letterhead image" src={letterhead} />
        </div>
      ) : null}
    </div>
  );
};

DealBranding.propTypes = {
  branding: PropTypes.instanceOf(Branding),
  deal: PropTypes.instanceOf(Deal),
};

export default DealBranding;
