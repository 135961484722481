import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import { Alert, Switch } from '@components/dmp';

import LensCheck from './LensCheck';

@autoBindMethods
export default class LensInfo extends Component {
  static propTypes = {
    lensChecks: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    errorMsg: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      hideNonScoring: false,
    };
  }

  renderBody() {
    const { lensChecks } = this.props;
    if (!lensChecks) return null;
    return (
      <>
        <div className="show-zero">
          <Switch
            id="toggle-non-scoring"
            checked={!this.state.hideNonScoring}
            size="small"
            onChange={() => this.setState({ hideNonScoring: !this.state.hideNonScoring })}
          >
            Show zero scores
          </Switch>
        </div>
        <div className="wrapper">
          <FormGroup>
            <ControlLabel>Lenses</ControlLabel>
            <div className="lens-info-body" data-cy="lens-info-body">
              <LensCheck lensChecks={lensChecks} hideNonScoring={this.state.hideNonScoring} />
            </div>
          </FormGroup>
        </div>
      </>
    );
  }

  renderError() {
    const { errorMsg } = this.props;
    return (
      <div className="lens-info-error">
        <Alert bsStyle="danger">{errorMsg}</Alert>
      </div>
    );
  }

  render() {
    const { show, close, lensChecks } = this.props;
    return (
      <Modal dialogClassName="lens-info-modal" show={show} onHide={close}>
        <Modal.Header closeButton>
          <span className="headline">Scorecard</span>
        </Modal.Header>
        <Modal.Body>{lensChecks ? this.renderBody() : this.renderError()}</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
