import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import Lens, { CLAUSE_LENS_PROMPTS, LENS_TYPES } from '@core/models/Lens';
import Team from '@core/models/Team';

import { Alert, Button, ButtonIcon, Dropdown, Icon, MenuItem } from '@components/dmp';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import ConditionsView from '@components/editor/ConditionsView';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import LensClauseEditor from './LensClauseEditor';
import LensVariableEditor from './LensVariableEditor';

@autoBindMethods
export default class LensSidebar extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
    onLinkingLens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      deletingLens: null,
      editingLens: null,
      lensType: 'variable',
      editingFilter: null,
      editingConfig: false,
      editingGroup: null,
      showLens: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.clean();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { editingLens } = this.state;

    if (prevProps.deal !== this.props.deal) {
      if (editingLens) {
        const lens = _.get(this.props.deal, `template.lenses[${editingLens.id}]`);
        if (lens && editingLens !== lens) {
          this.setState({ editingLens: lens });
        }
      }
    }
  }

  toggleShowLens(lens, action) {
    const { showLens } = this.state;

    switch (action) {
      case 'add':
        showLens.push(lens);
        this.setState({ showLens });
        break;
      case 'remove':
      default:
        let editedShowLens = _.remove(showLens, ({ id }) => {
          return id !== lens.id;
        });
        this.setState({ showLens: editedShowLens });
        break;
    }
  }

  async clean() {
    const { deal } = this.props;
    const { lenses } = deal.template;

    for (const lens of _.values(lenses)) {
      if (lens.isCreating) await Fire.saveLens(deal.dealID, lens, true);
    }
  }

  /////////////
  //FUNCTIONS//
  ////////////

  async newLensInstance() {
    const { deal, onLinkingLens } = this.props;
    const { lensType, showLens } = this.state;

    const rawLens = await Fire.generateLensInstance(deal.dealID, lensType);

    const lens = new Lens(rawLens);
    this.setState({ editingLens: lens, showLens: [...showLens, lens] });

    if (this.isClauseLensTab) {
      onLinkingLens(lens);
    }
  }

  reset(persistEditing) {
    const { onLinkingLens } = this.props;
    const { editingLens } = this.state;

    this.setState({
      editingLens: persistEditing ? editingLens : null,
      clauseFilter: null,
      editingConfig: false,
      editingFilter: null,
      deletingLens: null,
    });
    onLinkingLens();
  }

  async delete() {
    const { deal } = this.props;
    const { deletingLens } = this.state;

    await Fire.saveLens(deal.dealID, deletingLens, true);
    this.reset();
  }

  renderLensPannels() {
    const { deal } = this.props;
    const { lensType } = this.state;
    return (
      <div className="filter-bar">
        <ButtonGroup className="panel-tabs">
          {LENS_TYPES.map((typeDef, idx) => {
            if (typeDef.show(deal.template))
              return (
                <TooltipButton tipID={`tip-lens-${idx}`} key={idx} tip={typeDef.tip} placement="top">
                  <Button
                    dmpStyle="link"
                    active={typeDef.key === lensType}
                    onClick={() => this.setState({ lensType: typeDef.key })}
                  >
                    {typeDef.plural}
                  </Button>
                </TooltipButton>
              );
          })}
        </ButtonGroup>
      </div>
    );
  }

  renderLenses(lens, idx) {
    const { deal } = this.props;
    const { editingFilter, editingLens, clauseFilter, editingConfig, showLens, deletingLens } = this.state;
    const isEditing = editingLens?.id === lens.id;
    const isShowing = !!_.find(showLens, { id: lens.id });
    const isDeleting = deletingLens?.id === lens.id;

    return (
      <DealPanelItem borderBottom className="lens-block" key={idx}>
        {isEditing && (lens.isCreating || editingConfig) ? (
          this.isClauseLensTab ? (
            <LensClauseEditor
              deal={deal}
              lens={lens}
              onHide={(persistEditing) => this.reset(persistEditing)}
              clauseFilter={clauseFilter}
              editingConfig={editingConfig}
            />
          ) : (
            <LensVariableEditor
              deal={deal}
              lens={lens}
              onHide={(persistEditing) => this.reset(persistEditing)}
              valueFilter={editingFilter}
            />
          )
        ) : this.isClauseLensTab ? (
          this.renderClauseLensDisplay(lens, isEditing, isShowing, isDeleting)
        ) : (
          this.renderVariableLensDisplay(lens, isEditing, isShowing, isDeleting)
        )}
        {this.showAlert(lens) && this.renderAlerts()}
      </DealPanelItem>
    );
  }

  ///////////////////
  //VARIABLE LENS///
  /////////////////

  renderVariableLensDisplay(lens, isEditing, isShowing, isDeleting) {
    const { deal } = this.props;
    const { editingFilter } = this.state;

    return (
      <div className="display-block" key={lens.id} data-cy="lens-display-block">
        <div className={cx('left', { closed: !isShowing })}>
          <Icon name="variable" className="condition-icon" />
        </div>
        <div className="right">
          <div className="lens-topline">
            <div className={cx('lens-title')} data-cy="lens-variable-title">
              {lens.title}
            </div>
            {isShowing && (
              <ButtonIcon
                icon="chevronUp"
                className="openContent"
                size="default"
                onClick={() => this.toggleShowLens(lens, 'remove')}
              />
            )}
            {!isShowing && (
              <ButtonIcon
                icon="chevronDown"
                className="closeContent"
                size="default"
                onClick={() => this.toggleShowLens(lens, 'add')}
              />
            )}
          </div>
          {isShowing &&
            _.map(_.sortBy(lens.valueFilters, ['key']), (filter) => {
              return (
                <>
                  <div className={cx('lens-filter', { editing: editingFilter?.id === filter.id })} key={filter.key}>
                    <div
                      className="filter-display-label"
                      onClick={() => this.setState({ editingFilter: filter, editingLens: lens })}
                      data-cy="filter-display-label"
                    >
                      {filter.valueFilter.displayLabel}
                    </div>
                    <div
                      className="filter-risk-value"
                      onClick={() => this.setState({ editingFilter: filter, editingLens: lens })}
                    >
                      {filter.riskValue}
                    </div>
                  </div>
                  {_.size(filter.conditions) > 0 && (
                    <div>
                      <ConditionsView conditions={_.values(filter.conditions)} deal={deal} />
                    </div>
                  )}
                  {isEditing && isShowing && editingFilter?.id === filter.id && (
                    <LensVariableEditor
                      deal={deal}
                      lens={lens}
                      onHide={(persistEditing) => this.reset(persistEditing)}
                      valueFilter={editingFilter}
                    />
                  )}
                </>
              );
            })}
          {!isEditing && isShowing && (
            <div className="lens-filter add-new" onClick={() => this.setState({ editingLens: lens })}>
              <Icon name="plus2" size="small" />
              Add scoring factor
            </div>
          )}
          {isEditing && isShowing && !editingFilter && (
            <LensVariableEditor
              deal={deal}
              lens={lens}
              onHide={(persistEditing) => this.reset(persistEditing)}
              valueFilter={editingFilter}
            />
          )}
          {!isEditing && isShowing && !isDeleting && (
            <ButtonIcon
              icon="trash"
              className="delete"
              size="default"
              onClick={() => this.setState({ deletingLens: lens })}
            />
          )}
        </div>
      </div>
    );
  }

  ///////////////////
  //CLAUSE LENS/////
  /////////////////

  renderClauseLensDisplay(lens, isEditing, isShowing, isDeleting) {
    const { onLinkingLens, deal } = this.props;
    const { clauseFilter, editingConfig } = this.state;
    const { clauseFilters } = lens;
    const hasLinkingError = this.hasLinkingError(lens);

    return (
      <div className={cx('display-block', { error: hasLinkingError })} key={lens.id}>
        <div className="left">
          <Icon name="clause" className="condition-icon" />
        </div>
        <div className="right">
          <div className="lens-topline">
            <div
              className={cx('lens-title', 'lens-clause-title')}
              onClick={() => {
                this.setState({
                  editingConfig: true,
                  editingLens: lens,
                });
                onLinkingLens(lens);
              }}
            >
              {lens.title}
            </div>
            {isShowing && (
              <ButtonIcon
                icon="chevronUp"
                className="openContent"
                size="default"
                onClick={() => this.toggleShowLens(lens, 'remove')}
              />
            )}
            {!isShowing && (
              <ButtonIcon
                icon="chevronDown"
                className="closeContent"
                size="default"
                onClick={() => this.toggleShowLens(lens, 'add')}
              />
            )}
          </div>
          {hasLinkingError && this.isClauseLensTab && !isEditing && isShowing && this.renderLinkingAlert(lens)}
          {isShowing &&
            _.map(_.sortBy(clauseFilters, ['title']), (filter) => {
              return (
                <>
                  <div className={cx('lens-filter', { editing: clauseFilter?.id === filter.id })} key={filter.id}>
                    <div
                      className="filter-display-label"
                      onClick={() => this.setState({ clauseFilter: filter, editingLens: lens })}
                    >
                      {_.find(CLAUSE_LENS_PROMPTS, { id: filter.id }).title}
                    </div>
                    <div
                      className="filter-risk-value"
                      onClick={() => this.setState({ clauseFilter: filter, editingLens: lens })}
                    >
                      {filter.riskValue}
                    </div>
                  </div>
                  {isEditing && isShowing && filter.id === clauseFilter?.id && (
                    <LensClauseEditor
                      deal={deal}
                      lens={lens}
                      onHide={(persistEditing) => this.reset(persistEditing)}
                      clauseFilter={clauseFilter}
                      editingConfig={editingConfig}
                    />
                  )}
                </>
              );
            })}
          {!isEditing && isShowing && _.size(clauseFilters) < 3 && (
            <div className="lens-filter add-new" onClick={() => this.setState({ editingLens: lens })}>
              <Icon name="plus2" size="small" />
              Add scoring factor
            </div>
          )}
          {isEditing && isShowing && !clauseFilter && (
            <LensClauseEditor
              deal={deal}
              lens={lens}
              onHide={(persistEditing) => this.reset(persistEditing)}
              clauseFilter={clauseFilter}
              editingConfig={editingConfig}
              key={lens.id}
            />
          )}

          {!isEditing && isShowing && !isDeleting && (
            <ButtonIcon
              icon="trash"
              className="delete"
              size="default"
              onClick={() => this.setState({ deletingLens: lens })}
            />
          )}
        </div>
      </div>
    );
  }

  renderLinkingAlert(lens) {
    const { deal } = this.props;

    const section = _.find(deal.sections, { id: lens.relatedSections[0] });

    const alertText = 'Clause extraction must be enabled for the linked section. Would you like to enable it?';

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a onClick={() => Fire.saveSection(section, { extract: !section.extract })}>Enable extraction</a>
        </div>
      </Alert>
    );
  }

  renderAlerts() {
    const { deletingLens } = this.state;

    const alertText = `Are you sure you want to delete this ${deletingLens.type} Lens? All dependent conditions, target ranges, and values will also be deleted from Lens.`;

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a className="cancel" onClick={() => this.setState({ deletingLens: null })}>
            Cancel
          </a>
          <a onClick={this.delete}>Delete</a>
        </div>
      </Alert>
    );
  }

  ///////////
  //Getters//
  //////////

  hasLinkingError(lens) {
    const { deal } = this.props;

    let isSetForExtraction = true;
    if (_.size(lens.relatedSections) === 1) {
      //check if the section is set for extraction...
      const section = _.find(deal.sections, { id: lens.relatedSections[0] });
      isSetForExtraction = section.extract;
    } else {
      isSetForExtraction = false;
    }

    return !isSetForExtraction;
  }

  showAlert(lens) {
    const { deletingLens } = this.state;

    //We will add on for the fallback condition
    return deletingLens?.id === lens.id;
  }

  get lensCountText() {
    const { lensType } = this.state;

    return `${_.upperFirst(lensType)} Lenses (${this.lenses.length})`;
  }

  get lenses() {
    const { deal } = this.props;
    const { variableLenses, clauseLenses } = deal.template;

    let lenses = this.isVariableLensTab ? variableLenses : clauseLenses;
    return _.sortBy(lenses, ['relatedVariable', 'title']);
  }

  get isVariableLensTab() {
    const { lensType } = this.state;
    return lensType === 'variable';
  }

  get isClauseLensTab() {
    const { lensType } = this.state;
    return lensType === 'clause';
  }

  get showAddLensItem() {
    return this.isVariableLensTab || (this.isClauseLensTab && this.isNewClauseLens);
  }

  get isNewClauseLens() {
    const { editingLens } = this.state;

    return !editingLens;
  }

  get creatingMode() {
    const { deal } = this.props;

    return this.isVariableLensTab
      ? !!_.find(deal.template.variableLenses, { isCreating: true })
      : !!_.find(deal.template.clauseLenses, { isCreating: true });
  }

  render() {
    const { deal, openSettings } = this.props;
    const { lensType } = this.state;

    return (
      <div className="lens-sidebar" data-cy="lens-sidebar">
        {this.renderLensPannels()}
        <DealPanelItem borderBottom className="add-lens">
          <div className="lens-count" data-cy="lens-count">
            {this.lensCountText}
          </div>

          <div className="spacer" />

          <Button
            ref={this.refNewVital}
            size="small"
            icon="lens"
            onClick={() => this.newLensInstance()}
            data-cy="new-lens-btn"
            disabled={this.creatingMode}
          >
            New
          </Button>
        </DealPanelItem>

        {!deal.template.scoring && (
          <DealPanelItem borderBottom className="add-lens add-variable-lens">
            <Alert dmpStyle="danger" size="small" data-cy="matrix-alert">
              Please select a Scoring Matrix in <a onClick={openSettings}>Template settings</a> to enable Lens.
            </Alert>
          </DealPanelItem>
        )}

        {this.lenses.length > 0 ? (
          <div className="lens-list panel-scroll" data-cy="lens-list">
            {_.map(_.orderBy(this.lenses, ['isCreating', 'title'], ['desc', 'asc']), this.renderLenses)}
          </div>
        ) : (
          <DealPanelItem> {`No ${lensType} lenses defined`} </DealPanelItem>
        )}
      </div>
    );
  }
}
