import React, { Component, createRef } from 'react';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import Lens from '@core/models/Lens';
import LensValueFilter from '@core/models/LensValueFilter';
import { getOperators } from '@core/models/Operator';
import VariableFilter from '@core/models/VariableFilter';

import { Alert, Button, ButtonIcon, Dropdown, Icon, MenuItem, Validator } from '@components/dmp';

import VariableFilterView from '@components/VariableFilterView';
import Fire from '@root/Fire';

@autobindMethods
class LensVariableEditor extends Component {
  static defaultProps = {};

  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    lens: PropTypes.instanceOf(Lens),
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    valueFilter: PropTypes.object,
  };

  static defaultProps = {
    valueFilter: null,
    lens: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      lens: null,
      valueFilter: null,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  populate(props) {
    const { lens, valueFilter } = props;

    this.setState({
      lens: { ...lens.json },
      valueFilter: valueFilter ? JSON.parse(JSON.stringify(valueFilter)) : new LensValueFilter({}),
    });
  }

  validateRiskValue(riskValue) {
    return !isNaN(parseInt(riskValue));
  }

  async cancel(e) {
    const { lens, deal } = this.props;
    if (e) e.stopPropagation();

    if (lens.isCreating) await Fire.saveLens(deal.dealID, lens, true);
    this.props.onHide(false);
  }

  async save(e) {
    const { deal } = this.props;
    const { lens, valueFilter } = this.state;

    if (!this.props.lens.isCreating) lens.valueFilters[valueFilter.id] = valueFilter;

    await Fire.saveLens(deal.dealID, new Lens(lens));

    this.props.onHide(this.props.lens.isCreating);
  }

  async delete() {
    const { deal } = this.props;
    const { valueFilter, lens } = this.state;

    delete lens.valueFilters[valueFilter.id];
    await Fire.saveLens(deal.dealID, new Lens(lens));
    this.props.onHide(false);
  }

  updateLens(key, value) {
    const { lens } = this.state;

    lens[key] = value;

    this.setState({ lens });
  }

  updateValueFilter(key, value) {
    const { valueFilter } = this.state;

    valueFilter[key] = value;

    this.setState({ valueFilter });
  }

  addCondition(variableName) {
    const { deal } = this.props;
    const { valueFilter } = this.state;

    const variable = deal.variables[variableName];
    if (!variable) return;

    const operators = getOperators(variable.valueType);
    if (!operators.length) return;

    const condition = { operator: operators[0].key, valueType: variable.valueType, values: [] };
    // Create new VariableFilter with default operator based on value type
    const vf = new VariableFilter(variable.name, condition);
    valueFilter.conditions[variable.name] = vf;
    this.setState({ valueFilter });
  }

  updateCondition(varFilter) {
    const { valueFilter } = this.state;
    valueFilter.conditions[varFilter.variable] = varFilter;
    this.setState({ valueFilter });
  }

  removeCondition(varName) {
    const { valueFilter } = this.state;
    delete valueFilter.conditions[varName];
    this.setState({ valueFilter });
  }

  renderAddNewVariableLens() {
    const { deal } = this.props;
    const { lens } = this.state;
    const { variableLenses } = deal.template;
    const variables = _.filter(deal.simpleVariables, ({ valueType }) => {
      return !_.includes(['image', 'table', 'contact'], valueType);
    });

    return (
      <div className="lens-property-block">
        <div className="lens-editior-title">
          <Icon name="plus2" className="condition-icon" />
          Add variable lens
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Select related variable</div>
          <div className="variable-options" data-cy="variable-options">
            <Dropdown
              size="small"
              onSelect={(variable) => {
                this.updateLens('relatedVariable', variable.name);
                this.updateLens('title', variable?.displayName || variable.name);
              }}
              title={lens.relatedVariable || 'Select Variable'}
              id="new-lens-dd"
              block
              data-cy="select-variable-lens"
            >
              {_.map(variables, (variable) => (
                <MenuItem
                  key={variable.name}
                  eventKey={variable}
                  disabled={variableLenses && !!_.find(variableLenses, { relatedVariable: variable.name })}
                >
                  {variable.displayName || variable.name}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderFilter() {
    const { deal } = this.props;
    const { lens, valueFilter } = this.state;
    const selectedVariable = deal.variables[lens.relatedVariable];
    const { riskValue } = valueFilter;

    return (
      <div className="lens-property-block">
        <div className="lens-editior-title">
          <Icon name={!this.props.valueFilter ? 'plus2' : 'edit'} className="condition-icon" />
          {!this.props.valueFilter ? 'Add scoring factor' : 'Edit scoring factor'}
          {this.props.valueFilter && (
            <ButtonIcon
              icon="trash"
              className="delete-target-range"
              size="default"
              onClick={() => this.setState({ deleting: true })}
            />
          )}
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Add Target Range</div>

          <div className="variable-options" data-cy="variable-options">
            <VariableFilterView
              configOnly
              variable={selectedVariable}
              onChange={(valueFilter) => this.updateValueFilter('valueFilter', valueFilter)}
              filter={valueFilter?.valueFilter}
              template={deal}
            />
          </div>
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Add Risk Score</div>

          <div className="variable-options" data-cy="variable-options">
            <FormGroup className="risk-value dmp-validator-container">
              <FormControl
                bsSize="small"
                value={riskValue}
                placeholder="Enter an integer"
                onChange={(e) => this.updateValueFilter('riskValue', e.target.value)}
                data-cy="lens-risk-score"
              />
              <Validator
                validateEmpty
                value={riskValue}
                validate={this.validateRiskValue}
                onResult={_.noop}
                validTip="Valid risk value"
                invalidTip="Must be a valid integer"
              />
            </FormGroup>
          </div>
        </div>
        {_.map(valueFilter.conditions, this.renderCondition)}
        <div className="lens-property">
          <div className="lens-property-label">Add Condition</div>

          <div className="variable-options" data-cy="variable-options">
            <FormGroup className="dmp-validator-container">
              <Dropdown
                className="new-lens-condition-dd"
                size="small"
                id="new-lens-condition-dd"
                onSelect={(variable) => this.addCondition(variable)}
                title="Select Variable"
                data-cy="dd-add-condition"
                block
              >
                {_.map(deal.variables, (variable) => (
                  <MenuItem key={variable.name} eventKey={variable.name}>
                    {variable.displayName || variable.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
          </div>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderCondition(condition) {
    const { deal } = this.props;
    const { variable: varName } = condition;
    const variable = deal.variables[varName];

    return (
      <div
        className={cx('inline-condition', { hasError: condition.errorMsg })}
        key={varName}
        data-cy="render-condition"
      >
        <Icon name="conditional" className="condition-icon" />
        <div className="lens-property">
          <div className="lens-topline">
            <div className={cx('lens-property-label', { error: condition.errorMsg })}>{varName}</div>
            <ButtonIcon
              className="remove"
              icon="trash"
              size="default"
              onClick={() => this.removeCondition(varName)}
              data-cy="btn-remove-condition"
            />
          </div>

          <div className="variable-options" data-cy="variable-options">
            <VariableFilterView configOnly variable={variable} onChange={this.updateCondition} filter={condition} />
          </div>
        </div>
      </div>
    );
  }

  renderAlerts() {
    const { deleting } = this.state;

    const alertText = 'Are you sure you want to delete this target range?';

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a className="cancel" onClick={() => this.setState({ deleting: false })}>
            Cancel
          </a>
          <a onClick={this.delete}>Delete</a>
        </div>
      </Alert>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-var">
          Cancel
        </Button>

        <Button className="save" disabled={!this.isValid} size="small" onClick={this.save} data-cy={'btn-save-lens'}>
          Save
        </Button>
      </div>
    );
  }

  get isValid() {
    const { lens, valueFilter } = this.state;

    if (this.props.lens.isCreating) {
      return !!lens.relatedVariable && !!lens.title;
    } else {
      return !!valueFilter.valueFilter;
    }
  }

  render() {
    const { lens } = this.props;
    const { deleting } = this.state;
    const { isCreating } = lens;
    if (!this._isMounted) return null;

    return (
      <div
        className={cx('edit-lens-container', { inline: !isCreating, newRange: !isCreating && !this.props.valueFilter })}
      >
        {isCreating && (
          <div className="lens-editing-title">
            {' '}
            <div className="lens-editing-title" data-cy="lens-variable-title">
              <Icon name={lens.title ? 'variable' : 'lens'} />
              {lens.title || 'Add new lens'}
            </div>
          </div>
        )}
        {deleting && this.renderAlerts()}
        {!isCreating && this.renderFilter()}
        {isCreating && this.renderAddNewVariableLens()}
      </div>
    );
  }
}

export default LensVariableEditor;
