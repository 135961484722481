import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Section from '@core/models/Section';

import PageBreak from '@components/PageBreak';
import ConditionsView from '@components/editor/ConditionsView';
import PagesRenderView from '@components/editor/PagesRenderView';
import { measure } from '@components/section_types/SectionMeasurer';

@autoBindMethods
export default class TwoColumnsSection extends Component {
  static propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    measureClass: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      heightLeft: 0,
      heightRight: 0,
      dividerLines: 0,
    };
    this.refSelf = createRef();
  }

  componentDidMount() {
    const measurements = this.measure();
    this.setState(measurements);
  }

  componentDidUpdate() {
    const { heightLeft, heightRight } = this.state;
    const measurements = this.measure();

    if (measurements.heightLeft !== heightLeft || measurements.heightRight !== heightRight) {
      this.setState(measurements);
    }
    measure(this);
  }

  measure() {
    const { measureClass, section } = this.props;

    try {
      const elLeft = document.querySelector(`[data-sectionid="${section.id}"] .two-columns-lhs .${measureClass}`);
      const elRight = document.querySelector(`[data-sectionid="${section.id}"] .two-columns-rhs .${measureClass}`);
      const elDivLine0 = document.querySelector(`[data-sectionid="${section.id}"] .two-columns-measure .line0`);
      const elDivLine1 = document.querySelector(`[data-sectionid="${section.id}"] .two-columns-measure .line1`);

      const heightLeft = elLeft.getBoundingClientRect().height;
      const heightRight = elRight.getBoundingClientRect().height;
      const max = Math.max(heightLeft, heightRight);

      const trueLineHeight = elDivLine1.getBoundingClientRect().top - elDivLine0.getBoundingClientRect().top;

      const dividerLines = Math.round(max / trueLineHeight);

      return { heightLeft, heightRight, dividerLines };
    } catch (err) {
      return { heightLeft: 0, heightRight: 0, dividerLines: 0 };
    }
  }

  renderDivider() {
    const { section } = this.props;
    const { dividerLines } = this.state;

    const styleBody = _.merge({}, section.styleBody.css, section.style.css);

    const style = _.merge({}, styleBody, {
      width: '5%',
      textAlign: 'center',
    });
    const lines = [];
    for (let i = 0; i < dividerLines; i++) {
      lines.push(<div key={i}>{section.divider}</div>);
    }

    return (
      <div className="two-columns-divider" style={style} data-cy="two-columns-divider">
        <div className="two-columns-measure">
          <div className="line0">0</div>
          <div className="line1">1</div>
        </div>
        {lines}
      </div>
    );
  }

  render() {
    const { section, children } = this.props;

    // Add section spacing on parent container
    const style = { marginBottom: 0 };

    return (
      <div className="section-two-columns-container" data-cy="section-two-columns-container">
        <div
          ref={this.refSelf}
          className={cx('section-two-columns', { 'page-break': section.pageBreak })}
          data-sectionid={section.id}
          style={style}
          data-cy="section-two-columns"
        >
          {section.pageBreak && <PageBreak section={section} />}
          <div className="two-columns-lhs" data-cy="two-columns-lhs">
            {children[0] || null}
          </div>
          {this.renderDivider()}
          <div className="two-columns-rhs" data-cy="two-columns-rhs">
            {children[1] || null}
          </div>
        </div>
        {!!section.conditions.length && (
          <ConditionsView conditions={section.conditions} deal={section.deal} section={section} />
        )}
        <PagesRenderView section={section} />
      </div>
    );
  }
}
