import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import memoize from 'memoize-one';
import hash from 'object-hash';

import SectionType from '@core/enums/SectionType';
import { HEADER_FOOTER_SUB_SECTION_TYPES } from '@core/enums/SectionType';
import { MEDIA_QUERY_LARGE } from '@core/models/LayoutStyle';

import { Border, Numbering } from '@components/dmp';

import PageBreak from '@components/PageBreak';
import DealBranding from '@components/deal/DealBranding';
import AppendixSection from '@components/section_types/AppendixSection';
import CaptionSection from '@components/section_types/CaptionSection';
import ContentSection from '@components/section_types/ContentSection';
import ListSection from '@components/section_types/ListSection';
import SignatureSection from '@components/section_types/SignatureSection';

const { ONE_COL, TWO_COL } = HEADER_FOOTER_SUB_SECTION_TYPES;

// Enable/disable progressive "chunked" rendering for long deals
// We will remove this code if/when we confirm that the "virtualized" mode (react-window) is preferable
const CHUNKED = false;
@autoBindMethods
export default class ContractView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],
      totalSections: 0,
      cursor: -1,
      refreshing: false,
    };
  }
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
  dealData = memoize((deal, source) => {
    const sections = deal.applyConditions(source, false);
    const variables = deal.raw.variables || {};
    const users = deal.raw.users || {};
    return {
      sections,
      sectionHash: hash(deal.raw.sections),
      variables,
      variableHash: hash(variables),
      users,
      usersHash: hash(users),
    };
  });

  componentDidMount() {
    const { deal, source } = this.props;
    const { sections } = this.dealData(deal, source);
    this.setState({ sections });
  }

  // Only re-render all the deal content if something changed (section content or variables)
  componentDidUpdate(prevProps) {
    const curData = this.dealData(this.props.deal, this.props.source);
    const prevData = this.dealData(prevProps.deal, prevProps.source);

    const contentChanged = curData.sectionHash !== prevData.sectionHash;
    const variablesChanged = curData.variableHash !== prevData.variableHash;
    const usersChanged = curData.usersHash !== prevData.usersHash;

    if (contentChanged || variablesChanged || usersChanged) {
      this.setState({ sections: curData.sections });
    }
  }

  render() {
    //first filter view based on conditions
    const { deal, readonly } = this.props;
    const { sections } = this.state;
    const { activeFooters, activeHeaders } = deal;

    if (!deal) return null;

    const { layout } = deal.style;
    const enabledBorders = window.innerWidth >= MEDIA_QUERY_LARGE ? _.filter(deal.style.border, 'enabled') : null;
    const numberScheme = deal.style.type.LineNumbering.raw.native.numberScheme;

    let margins = layout.PageMargin.getPageWebMargins(window);

    if (activeHeaders && activeHeaders.length > 0) {
      margins.paddingTop = margins.paddingTop * 0.5;
    }
    if (activeFooters && activeFooters.length > 0) {
      margins.paddingBottom = margins.paddingBottom * 0.5;
    }

    return (
      <div className={cx('inside-paper-layout', 'source-view', { readonly })} style={margins}>
        {_.map(enabledBorders, (border) => (
          <Border borderStyle={border} pageMargin={deal.style.layout.PageMargin} />
        ))}
        {numberScheme !== 'off' && window.innerWidth >= MEDIA_QUERY_LARGE && <Numbering deal={deal}></Numbering>}
        {activeHeaders && (
          <div>
            {_.map(activeHeaders, (header) => {
              return this.renderHeaderFooter(header);
            })}
          </div>
        )}
        {deal.showLetterhead && <DealBranding branding={deal.branding} deal={deal} />}
        {deal.showTitle && (
          <div className="section-title" style={{ marginBottom: deal.style.layout.Section.web.bottom }}>
            <div style={deal.style.type.Title.css}>{deal.info.name}</div>
          </div>
        )}
        {sections.map(this.renderSection)}
        {activeFooters && (
          <div>
            {_.map(activeFooters, (footer) => {
              return this.renderHeaderFooter(footer);
            })}
          </div>
        )}
      </div>
    );
  }

  renderHeaderFooter(section) {
    const { cursor, refreshing } = this.state;
    const { hideMenu } = this.props;

    // TODO: remove this if we don't go with CHUNKED mode after all
    let skipRerender = false;
    if ((cursor === -1 || cursor > idx) && !refreshing && CHUNKED) {
      skipRerender = true;
    }

    const props = {
      container: this.props.container,
      user: this.props.user,
      activityMode: this.props.activityMode,
      activity: this.props.activity,
      toggleActivity: this.props.toggleActivity,
      toggleHistory: this.props.toggleHistory,
      showActivity: this.props.showActivity,
      notifyChanges: this.props.notifyChanges,
      trackChanges: this.props.trackChanges,
      toggleNotify: this.props.toggleNotify,
      toggleTrack: this.props.toggleTrack,
      noActivity: this.props.noActivity,
      readonly: this.props.readonly,
      variableIndex: this.props.variableIndex,
      location: this.props.location,
      history: this.props.history,
      section,
      ref: section.id,
      key: section.id,
      skipRerender,
    };

    let SectionComponent = null;

    if (section.subSectionType === ONE_COL) {
      SectionComponent = (
        <ContentSection {...props} indent sourceMode editableTitle hideMenu={hideMenu} setQueueFocus={this.onCreate} />
      );
    } else if (section.subSectionType === TWO_COL) {
      SectionComponent = (
        <CaptionSection section={section} measureClass="source-text" key={props.key}>
          {_.map(section.sourceChildren, (col, i) => {
            if(section.sectiontype === SectionType.TEMPLATE_FOOTER && section.isDefault && i == 0) {
              return <>{section.deal.info.license}</>
            }
            const colProps = _.merge(props, { section: col, key: col.id });
            return <ContentSection {...colProps} indent sourceMode editableTitle={false} hideMenu={hideMenu} />;
          })}
        </CaptionSection>
      );
    }

    return SectionComponent;
  }

  renderSection(section, idx) {
    const { cursor, refreshing } = this.state;
    const { container, hideMenu, toggleDealStatus, onSignature, deal, signed, locked } = this.props;

    const props = {
      container: this.props.container,
      user: this.props.user,
      activityMode: this.props.activityMode,
      activity: this.props.activity,
      toggleActivity: this.props.toggleActivity,
      toggleHistory: this.props.toggleHistory,
      showActivity: this.props.showActivity,
      notifyChanges: this.props.notifyChanges,
      trackChanges: this.props.trackChanges,
      toggleNotify: this.props.toggleNotify,
      toggleTrack: this.props.toggleTrack,
      noActivity: this.props.noActivity,
      readonly: this.props.readonly,
      variableIndex: this.props.variableIndex,
      location: this.props.location,
      history: this.props.history,
      section,
      ref: section.id,
      key: section.id,
      signed,
      locked,
    };

    switch (section.sectiontype) {
      case SectionType.COLUMNS:
        return (
          <div className={cx('section-columns', { 'page-break': section.pageBreak })} key={section.id}>
            {section.pageBreak && <PageBreak section={section} />}
            {_.map(section.sections, (col) => {
              const colProps = _.merge(props, { section: col, key: col.id });
              return <ContentSection {...colProps} indent sourceMode editableTitle={false} hideMenu={hideMenu} />;
            })}
          </div>
        );
      case SectionType.HEADER:
      case SectionType.SOURCE:
        return <ContentSection {...props} container={container} indent sourceMode editableTitle hideMenu={hideMenu} />;
      case SectionType.LIST:
        return <ListSection {...props} />;
      case SectionType.APPENDIX:
        return <AppendixSection {...props} />;
      case SectionType.SIGNATURE:
        return (
          <SignatureSection
            {...props}
            toggleDealStatus={toggleDealStatus}
            container={container}
            onSignature={onSignature}
          />
        );
      case SectionType.CAPTION:
        const [lhs, rhs] = section.sourceChildren;

        if (!section.sourceChildren.some((child) => child.passesConditions)) return null;

        // Return empty so that the section is "held open" in case it is conditionally hidden
        return (
          <CaptionSection section={section} measureClass="source-text" key={section.id}>
            {(lhs && lhs.passesConditions && this.renderSection(lhs, 0)) || <></>}
            {(rhs && rhs.passesConditions && this.renderSection(rhs, 1)) || <></>}
          </CaptionSection>
        );
      default:
        return null;
    }
  }
}
