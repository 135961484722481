/* eslint-disable react/no-find-dom-node */
import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import InviteStatus from '@core/enums/InviteStatus';
import Bundle from '@core/models/Bundle';
import { DEAL_TYPE } from '@core/models/Deal';
import { REALTIME_EVENTS } from '@core/models/DealRecord';
import { Dt, dt } from '@core/utils';
import { getDataURL } from '@core/utils';

import { ButtonIcon, MenuItem } from '@components/dmp';
// Exception!
import DropdownMenu from '@components/dmp/DropdownMenu';

import ContractExporter from '@components/deal/DealHeader/ContractExporter';
import API from '@root/ApiClient';
import Dealer, { Category } from '@root/Dealer';
import Fire from '@root/Fire';

import LeaveDeal from './LeaveDeal';
import LensInfo from './LensInfo';

/*
  This component could normally be a regular DropdownDots but because the /contracts
  DataTeble is scrollable, we cannot use a regular Dropdown. If we do, the DropdownMenu
  will not follow the scrolling of the page.

  This is the only instance in the app where we needed this. That said, at some point we should abstract this
  "Custom Overlay Dropdown Menu" mechanism to be usable by any DataTable Dropdowns.
  (Or maybe we won't need it if we upgrade ReactBootstrap and it's not a problem anymore)

  Also, becasue it is mimicing the @dmp/DropdownDots, we had to set classNames manually: "dmp-dd-dots dmp-dd-dots-faded"
  so that is looks identical.
*/

const CustomPopover = ({ className, style, children }) => (
  <div className={cx('popover-edit-deal', className)} style={{ ...style }} data-cy="popover-edit-deal">
    {children}
  </div>
);

CustomPopover.propTypes = {
  className: PropTypes.any,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

@autoBindMethods
export default class DealEditDropdown extends Component {
  static propTypes = {
    dealRecord: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    confirmDelete: PropTypes.func.isRequired,
    restoreDeals: PropTypes.func.isRequired,
    realtimeUpdate: PropTypes.func.isRequired,
    onShowTagger: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    trash: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      exporting: false,
      showLensInfo: false,
      lensChecks: null,
      lensErrorMsg: null,
      dataURL: null,
      fileName: null,
      version: null,
      leave: false,
      dealDeleted: false,
    };
    this.downloadLink = React.createRef();
  }

  get isOwner() {
    const { dealRecord, user } = this.props;
    const role = dealRecord.userRole(user.id);
    return role === DealRole.OWNER;
  }

  get isObserverOwner() {
    const { dealRecord, user } = this.props;
    const { teamMemberships } = user;
    const observerOwner = teamMemberships?.[dealRecord.sourceTeam]?.observerRole === 'owner';
    return observerOwner;
  }

  get hasActions() {
    const { trash } = this.props;
    if (!trash) {
      return true;
    } else if (trash && (this.isOwner || this.isObserverOwner)) {
      return true;
    }
    return false;
  }

  handleAction(action) {
    const { confirmDelete, restoreDeals, dealRecord, history } = this.props;

    switch (action) {
      case 'archive':
      case 'unarchive':
        this.toggleArchivedTag();
        break;
      case 'reject':
        this.rsvp(InviteStatus.REJECTED);
        break;
      case 'view':
        history.push(`/deals/${dealRecord.dealID}`);
        break;
      case 'leave':
        this.setState({ leave: true });
        break;
      case 'delete':
      case 'permanentlyDelete':
        confirmDelete([dealRecord]);
        break;
      case 'tags':
        this.showTagger();
        break;
      case 'export':
        this.setupExport();
        break;
      case 'lensInfo':
        this.showLensInfo();
        break;
      case 'download':
        this.downloadDocument();
        break;
      case 'restore':
        restoreDeals([dealRecord]);
        break;
      default:
        break;
    }

    this.handleHideMenu();
  }

  async setupExport() {
    const { dealRecord } = this.props;
    const deal = await Fire.getDeal(dealRecord.dealID);

    if (deal.isBundle) {
      let bundleRaw;
      try {
        bundleRaw = await API.call('getBundle', { dealID: deal.bundleID });
      } catch (error) {
        console.log(error);
      }

      if (bundleRaw) {
        const bundle = new Bundle(bundleRaw.parent, bundleRaw.children);
        deal.bundle = bundle;
      }
    }

    this.setState({ deal, exporting: true });
  }

  async showLensInfo() {
    const { dealRecord } = this.props;
    let lensChecks;
    try {
      lensChecks = await API.call('getDealLens', { dealID: dealRecord.dealID });
    } catch (error) {
      lensChecks = null;
      this.setState({
        lensErrorMsg: 'An error occurred while retrieving lens information for this deal.',
        showLensInfo: true,
      });
    }

    if (lensChecks) {
      this.setState({ lensChecks, showLensInfo: true });
    }
  }

  async downloadDocument() {
    try {
      const { dealRecord } = this.props;
      const raw = await API.call('getDocumentData', { dealID: dealRecord.dealID });
      const arrayBuffer = new Uint8Array(raw.data.data).buffer;
      const dataURL = getDataURL(arrayBuffer);
      const fileName = raw.name;
      await this.setState({ dataURL, fileName });
      this.downloadLink.current.click();
    } catch (err) {
      console.log(err);
    }
  }

  rsvp(inviteResponse) {
    const { dealRecord, history, user } = this.props;
    const inviteID = dealRecord.inviteID;
    const duKey = user.id;

    API.call('rsvp', { user, inviteID, duKey, inviteResponse }, () => {
      // If the response is acceptance, this does not affect the user.deals tree so we need to manually reload
      // for rejection, the deal will be removed so firebase listener will fire
      if (inviteResponse == InviteStatus.ACCEPTED) {
        Dealer.call({ category: Category.INVITE, action: 'accept', label: 'dashboard' });
        // Once accepted, go to the deal
        history.push(`/${this.pathRoot}/${dealRecord.dealID}`);
      } else {
        Dealer.call({ category: Category.INVITE, action: 'reject', label: 'dashboard' });
      }
    });
  }

  leaveDeal() {
    const { dealRecord, user, realtimeUpdate } = this.props;
    Dealer.call({ category: Category.DEAL, action: 'leave' });
    Fire.leaveDeal(user.id, dealRecord.dealID);
    realtimeUpdate(dealRecord, 'leave');
  }

  toggleArchivedTag() {
    const { dealRecord, user } = this.props;
    const tag = 'archived';

    const hasTag = dealRecord.tags.indexOf(tag) > -1;
    const realtimeEvent = hasTag ? REALTIME_EVENTS.UNTAG : REALTIME_EVENTS.TAG;

    if (!hasTag) dealRecord.tags.push(tag);
    else dealRecord.tags.splice(dealRecord.tags.indexOf(tag), 1);

    Dealer.call({ category: Category.DEAL, action: `${realtimeEvent}:${tag}` });
    Fire.updateDealUserTags(user.id, dealRecord.dealID, dealRecord.tags);

    this.onTagsUpdated(dealRecord, tag, realtimeEvent);
  }

  onTagsUpdated(dealRecord, tag, realtimeEvent) {
    this.props.realtimeUpdate(dealRecord, realtimeEvent, tag);
  }

  handleShowMenu() {
    this.setState({ showMenu: true });
  }

  handleHideMenu() {
    this.setState({ showMenu: false });
  }

  showTagger() {
    const { dealRecord, onShowTagger } = this.props;
    onShowTagger(dealRecord.dealID);
  }

  renderDocumentDropdown() {
    const { dealRecord, user } = this.props;
    const archived = dealRecord.tags.indexOf('archived') > -1;

    // If this DealRecord is being rendered by a user who only has access as an observer,
    // the current user will NOT be found on the DealRecord
    // In which case the only "action" that can be taken is to view it (which will then auto-add user to that Deal)
    const isObserver = !_.find(dealRecord.users, { uid: user.id });
    const showLensTab = dealRecord.grade && (this.isOwner || this.isObserverOwner);

    return (
      <DropdownMenu open={true} onClose={this.handleHideMenu} onSelect={this.handleAction}>
        <MenuItem eventKey="view" data-cy="deal-edit-view">
          View {Dt}
        </MenuItem>

        {!isObserver && <MenuItem divider />}

        {!isObserver && (
          <MenuItem
            eventKey={archived ? 'unarchive' : 'archive'}
            data-cy={archived ? 'deal-edit-unarchive' : 'deal-edit-archive'}
          >
            {archived ? 'Unarchive' : 'Archive'}
          </MenuItem>
        )}

        {(this.isOwner || isObserver) && (
          <MenuItem
            eventKey={dealRecord.isExternal ? 'download' : 'export'}
            data-cy={dealRecord.isExternal ? 'deal-edit-download' : 'deal-edit-export'}
          >
            {dealRecord.isExternal ? 'Download File' : 'Export as...'}
          </MenuItem>
        )}

        {dealRecord.canLeave(user.id) && (
          <MenuItem eventKey="leave" data-cy="deal-edit-leave">
            Leave {dt}
          </MenuItem>
        )}

        {!isObserver && (
          <MenuItem eventKey="tags" data-cy="deal-edit-tags">
            Edit tags
          </MenuItem>
        )}

        {(this.isOwner || this.isObserverOwner) && (
          <MenuItem className="text-danger" eventKey="delete" data-cy="deal-edit-delete">
            Move to trash
          </MenuItem>
        )}

        {showLensTab && <MenuItem divider />}
        {showLensTab && (
          <MenuItem eventKey="lensInfo" data-cy="deal-edit-lensInfo">
            Scorecard
          </MenuItem>
        )}
      </DropdownMenu>
    );
  }

  renderTrashDropdown() {
    return (
      <>
        <DropdownMenu open={true} onClose={this.handleHideMenu} onSelect={this.handleAction}>
          <MenuItem eventKey="restore" data-cy="deal-edit-restore">
            Restore
          </MenuItem>
          <MenuItem className="text-danger" eventKey="permanentlyDelete" data-cy="deal-edit-permanentlyDelete">
            Permanently delete
          </MenuItem>
        </DropdownMenu>
      </>
    );
  }

  render() {
    const { showMenu, exporting, deal, dataURL, fileName, leave, showLensInfo, lensChecks, lensErrorMsg } = this.state;
    const { user, subscription, trash } = this.props;
    if (!this.hasActions) return null;
    return (
      <>
        <div className={cx('dmp-dd-dots', 'dmp-dd-dots-faded')}>
          <ButtonIcon
            className="btn btn-link"
            data-cy="dd-deal-edit"
            icon="dotsHorizontal"
            onClick={this.handleShowMenu}
            ref={(element) => (this.target = element)}
          />

          <Overlay
            show={showMenu}
            onHide={this.handleShowMenu}
            placement="bottom"
            target={() => ReactDOM.findDOMNode(this.target)}
          >
            <CustomPopover> {trash ? this.renderTrashDropdown() : this.renderDocumentDropdown()}</CustomPopover>
          </Overlay>
        </div>

        {exporting && (
          <ContractExporter
            show={exporting}
            close={() => this.setState({ exporting: false })}
            deal={deal}
            user={user}
            subscription={subscription}
          />
        )}

        {showLensInfo && (
          <LensInfo
            show={showLensInfo}
            close={() => this.setState({ showLensInfo: false })}
            lensChecks={lensChecks}
            errorMsg={lensErrorMsg}
          />
        )}

        {dataURL && (
          <a className="d-none" ref={this.downloadLink} href={dataURL} download={fileName}>
            {fileName}
          </a>
        )}

        {leave && (
          <LeaveDeal
            onHide={() => {
              this.setState({ leave: false });
            }}
            onConfirm={this.leaveDeal}
          />
        )}
      </>
    );
  }
}
