import { chain, filter, find, forEach, get, isEmpty, isNil, merge, pick } from 'lodash';

import DealRole from '../enums/DealRole';
import DealStatus, { STEPS } from '../enums/DealStatus';
import { FILEVINE_SERVICE } from '../enums/IntegrationServices';
import InviteStatus from '../enums/InviteStatus';
import { getSafeKey } from '../utils/Generators';
import { BEHAVIOR, DEAL_TYPE } from './Deal';
import Variable, { BATCH_ID_VAR, ValueType, VariableType, discoverValueType } from './Variable';

// Different actions have differet sets of required columns
export const RESERVED_COLUMNS = [
  {
    key: 'filename',
    display: 'Filename',
    actions: ['upload'],
  },
  {
    key: 'title',
    display: 'Title',
    actions: ['upload', 'create'],
  },
  {
    key: 'status',
    display: 'Status',
    actions: ['upload'],
    processor: (value) => {
      const status = value ? value.toLowerCase() : null;
      // Since status is only enabled for 3PP, validate all the Deal steps
      if (!find(STEPS, { key: status })) return null;

      return status;
    },
  },
];

// generate a list of empty column names for csv output
export const getBoilerplate = (dealTemplate, action) => {
  const cols = {};

  forEach(RESERVED_COLUMNS, (col) => {
    if (col.actions.includes(action)) {
      cols[`_${col.key}`] = null;
    }
  });

  if (dealTemplate) {
    forEach(dealTemplate.parties, (party) => {
      cols[`${VariableType.PARTY}${party.partyID}`] = {
        email: null,
        fullName: null,
        title: null,
        org: null,
        address: null,
        addressProperties: {
          line1: null,
          line2: null,
          city: null,
          state: null,
          postalCode: null,
          country: null,
        },
        phone: null,
      };
    });

    forEach(dealTemplate.filterableVariables, (variable) => {
      cols[`${VariableType.SIMPLE}${variable.name}`] = null;
    });

    // Added for filevine dealConnection collection ids batch import
    if (dealTemplate?.fvCollections?.length) {
      // Project ID and Section ID added to CSV to support batch import for collections
      // spanning in multiple projects with similar collections structure.
      cols[`${VariableType.CONNECTED}${FILEVINE_SERVICE.key}:projectId`] = null;
      cols[`${VariableType.CONNECTED}${FILEVINE_SERVICE.key}:sectionId`] = null;
      forEach(dealTemplate?.fvCollections, (collection) => {
        cols[`${VariableType.CONNECTED}${FILEVINE_SERVICE.key}:${collection}`] = null;
      });
    }
  }

  return cols;
};

export default class DealMetaCSV {
  raw = null;

  filename = null;
  title = null;
  status = DealStatus.DRAFT.data;

  // Filled in after deal creation
  dealID = null;
  processStatus = 'missing';

  variables = {};
  connections = {};
  users = [];

  constructor(json, action) {
    this.raw = json;

    // Look through the reserved columns specified for this action
    // and accept any values that are found
    forEach(RESERVED_COLUMNS, (col) => {
      if (col.actions.includes(action)) {
        let val = get(json, `_${col.key}`, null);
        if (col.processor) val = col.processor(val);
        if (val !== null) this[col.key] = val;
      }
    });

    forEach(json, (val, key) => {
      switch (key[0]) {
        // Deal properties are already handled above - ignore
        case VariableType.CALCULATED:
          break;

        case VariableType.PARTY:
          const partyName = key.slice(1).split('.')[0];
          const partyID = getSafeKey(partyName, false, true);

          // Ensure we capture the party data as a variable if we have not done so already
          if (!this.variables[partyID]) {
            this.variables[partyID] = {
              type: VariableType.PARTY,
              displayName: partyName,
              name: partyID,
            };
          }

          // If specified as @Party.property format, it already comes as a structured object from csvtojson conversion (super cool!)
          // If not (e.g., @Party), convert to object
          if (typeof val !== 'object') val = { fullName: val };

          // Put the partyID on the user object
          val.partyID = partyID;
          // Default to ADDED invite status if not specified, which is the state for external (i.e., non-existant) users with whom the deal has not yet been shared
          if (!val.inviteStatus) val.inviteStatus = InviteStatus.ADDED;
          // And default to read-only role if not specified
          if (!val.role) val.role = DealRole.VIEWER;
          this.users.push(val);
          break;

        case VariableType.SIMPLE:
          const displayName = key.slice(1);
          const name = getSafeKey(displayName, false, true);
          this.variables[name] = {
            type: VariableType.SIMPLE,
            name,
            displayName,
            value: val,
            valueType: key === `#${BATCH_ID_VAR.name}` ? BATCH_ID_VAR.valueType : discoverValueType(val),
          };
          break;
        case VariableType.CONNECTED:
          const keyName = key.slice(1);
          let connectionType = keyName;
          // Filevine type connection
          if (keyName.includes(':')) {
            const [connectionType, idField] = keyName.split(':');
            const safeIdField = getSafeKey(idField, false, true);
            const connectionIdFields = this.connections[connectionType];
            this.connections[connectionType] = connectionIdFields
              ? { ...connectionIdFields, [safeIdField]: val }
              : { [safeIdField]: val };
          } else {
            // Regular connections
            this.connections[connectionType] = { id: val };
          }
          break;
        default:
          break;
      }
    });
  }

  // This is the exact same filtering and sorting as Deal.filterableVariables;
  // however here we're coming from a CSV so we don't have knowledge of ValueType
  get simpleVariables() {
    return chain(this.variables).filter({ type: VariableType.SIMPLE }).sortBy(['displayName', 'name']).value();
  }

  // This is an adaptation of the getter on DealRecord
  // to enable us to use the same parties column definition from Columns.jsx
  get usersByParty() {
    //group list of users by party
    const parties = {};
    forEach(this.users, (u) => {
      if (!u.partyID) return;
      if (!parties[u.partyID]) parties[u.partyID] = [];
      parties[u.partyID].push(u);
    });
    return parties;
  }

  buildDealCreationParams(currentUser, dealTemplate, external = false) {
    const { style } = dealTemplate.raw;
    const templateParams = {};
    const isExternal = external || !!this.filename;
    const connections = [];

    // Merge CSV users (parties) with teammates defined in template -- match on email
    forEach(dealTemplate.users, (teammate) => {
      // Only include teammates who are supposed to be auto-included!
      if (!teammate.auto) return;
      const dealUser = find(this.users, (du) => !!du.email && du.email.toLowerCase() === teammate.email.toLowerCase());
      if (dealUser) {
        // Fill in keys for existing users, and set to ACCEPTED
        // Which is standard state for added team members (i.e., to show in their dashboard)
        dealUser.key = teammate.uid;
        dealUser.uid = teammate.uid;
        dealUser.inviteStatus = InviteStatus.ACCEPTED;
        // Ensure that current user (the one doing the uploading) is always an owner
        if (dealUser.uid === currentUser.id) {
          dealUser.inviteStatus = InviteStatus.OWNED;
          dealUser.role = DealRole.OWNER;
        }
      }
      // For other teammates, just push them into DealUser array using values defined in template
      else {
        this.users.push(
          merge(
            pick(teammate, [
              'uid',
              'role',
              'email',
              'partyID',
              'fullName',
              'address',
              'addressProperties',
              'title',
              'org',
              'phone',
            ]),
            {
              key: teammate.uid,
              inviteStatus: InviteStatus.ACCEPTED,
            }
          )
        );
      }
    });

    // Merge variable values into template
    const variables = {};
    // Only select non-spelled simple variables
    const templateVariables = filter(dealTemplate.variables, {
      type: VariableType.SIMPLE,
      isDerived: false,
    });

    forEach(templateVariables, (variable) => {
      // Start with template defaults
      variables[variable.name] = variable.json;

      // Skip images, we can't populate them from the CSV
      if (variable.valueType === ValueType.IMAGE) {
        return;
      }

      // If there's a supplied value from csv, override
      const csvVar = this.variables[variable.name];
      if (csvVar && csvVar.value) {
        variables[variable.name].value = csvVar.value;
      }
    });

    forEach(this.variables, (variable) => {
      if (!find(templateVariables, { name: variable.name })) {
        const variableModel = new Variable({ ...variable });
        variables[variable.name] = variableModel.json;
        if (variable.value) {
          variables[variable.name].value = variable.value;
        }
      }
    });

    // We also need the Party variables in there
    const partyVars = filter(dealTemplate.raw.variables, {
      type: VariableType.PARTY,
    });
    forEach(partyVars, (partyVar) => {
      if (!variables[partyVar.name]) variables[partyVar.name] = partyVar;
    });

    const connectedVars = filter(dealTemplate.raw.variables, {
      type: VariableType.CONNECTED,
    });
    forEach(connectedVars, (connectedVar) => {
      variables[connectedVar.name] = connectedVar;
    });

    // Copy the PDF character set
    if (dealTemplate.template.pdfCharSet) templateParams.pdfCharSet = dealTemplate.template.pdfCharSet;

    // Transfer behaviors
    Object.values(BEHAVIOR).forEach((behavior) => {
      if (behavior.preventTransfer) return;
      templateParams[behavior.key] = !isNil(dealTemplate.template[behavior.key])
        ? dealTemplate.template[behavior.key]
        : behavior.defaultValue;
    });

    forEach(this.connections, (value, key) => {
      //create connections based on dealConnection (~) columns from the CSV stored in this.connections
      if (key === FILEVINE_SERVICE.key) {
        const fvCSVIdFields = value;

        if (fvCSVIdFields) {
          const fvConnection = {
            type: FILEVINE_SERVICE.key,
            id: `${fvCSVIdFields?.projectId}_${fvCSVIdFields?.sectionId}`,
            idFields: fvCSVIdFields,
          };
          connections.push(fvConnection);
        }
      } else {
        if (!isEmpty(value.id)) {
          connections.push({ type: key, id: value.id });
        }
      }
    });

    return {
      ...templateParams,
      user: currentUser,
      title: this.title || dealTemplate.template.title,
      users: this.users,
      teamID: dealTemplate.template.team,
      templateKey: dealTemplate.template.key,
      variables,
      status: this.status || DealStatus.DRAFT.data,
      sections: isExternal ? null : dealTemplate.raw.sections,
      dealType: isExternal ? DEAL_TYPE.EXTERNAL : DEAL_TYPE.NATIVE,
      connections,
      style: style,
      hasLenses: _.size(dealTemplate.template.lenses) > 0,
      documentAI: dealTemplate.template.documentAI,
    };
  }

  // Ensure that all of the parties and variables in the CSV row are indeed contained in the Template
  validateParties(dealTemplate) {
    const invalidParties = [];

    forEach(this.users, (du) => {
      if (!dealTemplate.getPartyByID(du.partyID)) {
        invalidParties.push(du.partyID);
      }
    });

    return invalidParties;
  }

  validateVariables(dealTemplate) {
    const invalidVars = [];

    forEach(this.simpleVariables, (rawVar) => {
      if (rawVar.name !== BATCH_ID_VAR.name && !dealTemplate.variables[rawVar.name]) {
        invalidVars.push(rawVar.name);
      }
    });

    return invalidVars;
  }
}
