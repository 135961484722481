import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { CLAUSE_LENS_PROMPTS } from '@core/models/Lens';
import VariableFilter from '@core/models/VariableFilter';

import { Icon } from '@components/dmp';

@autoBindMethods
export default class LensCheck extends Component {
  static propTypes = {
    lensChecks: PropTypes.object.isRequired,
    hideNonScoring: PropTypes.bool.isRequired,
  };

  renderLens({ lens, lensFilterChecks }, idx) {
    const filterChecks = this.props.hideNonScoring
      ? _.filter(lensFilterChecks, ({ risk }) => {
          return risk !== 0;
        })
      : lensFilterChecks;

    if (_.size(filterChecks) === 0) return;

    return (
      <div key={idx} className="lens-check">
        <Icon name={lens.type === 'variable' ? 'variable' : 'clause'} className="icon-lens" />
        <div className="lens-info">
          <div className="item-label" data-cy="lens-title">
            {lens.title}
          </div>
          <div>{_.map(filterChecks, this.renderLensFilterChecks)}</div>
        </div>
      </div>
    );
  }

  renderLensFilterChecks({ displayLabel, id, risk }) {
    const riskText = risk > 0 ? `+${risk}` : risk;
    return (
      <div className="lens-filter-check item-sub" key={id} data-cy="lens-sub">
        <div className="lens-filter-label">{displayLabel}</div>
        <div className="lens-filter-score">{riskText}</div>
      </div>
    );
  }

  render() {
    const { lensChecks } = this.props;

    return (
      <>
        {_.map(_.orderBy(lensChecks.checks, ['lens.type', 'lens.title'], ['desc', 'asc']), this.renderLens)}
        <div className="lens-summary" data-cy="lens-summary-has-risk">
          <div className="item-label risk-points">Score</div>
          <div className="spacer" />
          <div className="risk-points">{lensChecks.grade}</div>
        </div>
        <div className="lens-summary" data-cy="lens-summary-risk-point">
          <div className="spacer" />
          <div className="risk-points">{`(${lensChecks.totalRisk} points)`}</div>
        </div>
      </>
    );
  }
}
