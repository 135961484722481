import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Section from '@core/models/Section';

import HeaderFooterBreak from '@components/HeaderFooterBreak';
import PageBreak from '@components/PageBreak';
import { measure } from '@components/section_types/SectionMeasurer';

@autoBindMethods
export default class CaptionSection extends Component {
  static propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    // CaptionSection is used in both Draft (SectionEditor) and Flow (ContentSection)
    // So we need to specify the wrapping class to use for column height measurements, as it differs
    measureClass: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      heightLeft: 0,
      heightRight: 0,
      dividerLines: 0,
    };
    this.refSelf = createRef();
  }

  componentDidMount() {
    const measurements = this.measure();
    this.setState(measurements);
  }

  componentDidUpdate() {
    const { heightLeft, heightRight, dividerLines } = this.state;

    const measurements = this.measure();

    if (
      measurements.heightLeft !== heightLeft ||
      measurements.heightRight !== heightRight ||
      measurements.dividerLines !== dividerLines
    ) {
      this.setState(measurements);
    }

    measure(this);
  }

  measure() {
    const { measureClass, section } = this.props;

    try {
      const elLeft = document.querySelector(`[data-sectionid="${section.id}"] .caption-lhs .${measureClass}`);
      const elRight = document.querySelector(`[data-sectionid="${section.id}"] .caption-rhs .${measureClass}`);
      const elDivLine0 = document.querySelector(`[data-sectionid="${section.id}"] .caption-measure .line0`);
      const elDivLine1 = document.querySelector(`[data-sectionid="${section.id}"] .caption-measure .line1`);

      const heightLeft = elLeft.getBoundingClientRect().height;
      const heightRight = elRight.getBoundingClientRect().height;

      // Compute how many lines we need based on the measured line height
      // This way the divider should always perfectly fill/match the height of the largest column
      const trueLineHeight = elDivLine1.getBoundingClientRect().top - elDivLine0.getBoundingClientRect().top;

      const max = Math.max(heightLeft, heightRight, trueLineHeight); //There's a chance that the line height is greater than the content height

      // The number of lines should actually be exactly correct (uncomment console.log below to see!)
      // But round it just to be safe
      const dividerLines = Math.round(max / trueLineHeight); //Do this to make sure that it doesn't overflow

      // Leave here commented out -- useful for debug
      // console.log({heightLeft, heightRight, trueHeight: trueLineHeight, lines: max/trueLineHeight});
      return { heightLeft, heightRight, dividerLines };
    } catch (err) {
      return { heightLeft: 0, heightRight: 0, dividerLines: 0 };
    }
  }

  renderDivider() {
    const { section } = this.props;
    const { dividerLines } = this.state;

    const styleBody = _.merge({}, section.styleBody.css, section.style.css);

    const style = _.merge({}, styleBody, {
      width: section.dividerWidth + '%',
      textAlign: 'center',
      lineHeight: 1, //We will instead user bottom padding on each line so that the divider starts at the top
    });

    const dividerStyle = {
      paddingBottom: `${(section.dividerLineSpacing - 1) * parseInt(style.fontSize)}px`,
    };

    // We've perfectly measured the true line height and maximum column size (in componentDidMount/componentDidUpdate)
    // So we know the exact number of lines to automatically render for the divider column
    const lines = [];
    for (let i = 0; i < dividerLines; i++) {
      lines.push(
        <div key={i} style={dividerStyle}>
          {section.divider}
        </div>
      );
    }

    return (
      <div className="caption-divider" style={style} data-cy="caption-divider">
        {/* NB, this leaves an invisible div in the DOM, which is harmless. 
        We could also componentize this to a <LineMeasurer/> dmp component if useful */}
        <div className="caption-measure">
          <div className="line0" style={dividerStyle}>
            0
          </div>
          <div className="line1" style={dividerStyle}>
            1
          </div>
        </div>
        {lines}
      </div>
    );
  }

  render() {
    const { section, children } = this.props;

    // Add section spacing on parent container
    const style = { marginBottom: _.get(section.deal.style, 'layout.Section.web.bottom', 0) };


    return (
      <div
        ref={this.refSelf}
        className={cx(
          'section-caption',
          { 'page-break': section.pageBreak },
          { 'header-footer-break': section.isTemplateHeaderFooter }
        )}
        data-sectionid={section.id}
        style={style}
        data-cy="section-caption"
      >
        {section.pageBreak && <PageBreak section={section} />}
        {section.isTemplateFooter && <HeaderFooterBreak section={section} />}
        {!!children[0] && (
          <div className="caption-lhs" data-cy="caption-lhs">
            {children[0]}
          </div>
        )}
        {!!children[0] && !!children[1] && section.dividerEnabled && this.renderDivider()}
        {!!children[1] && (
          <div className="caption-rhs" data-cy="caption-rhs">
            {children[1]}
          </div>
        )}
        {section.isTemplateHeader && <HeaderFooterBreak section={section} />}
      </div>
    );
  }
}
