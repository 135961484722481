import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';

import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import SectionType from '@core/enums/SectionType';

import { Icon } from '@components/dmp';

import ActivityView from '@components/ActivityView';

@autoBindMethods
export default class ActivitySection extends Component {
  //TODO: List Props and defaultProps

  constructor(props) {
    super(props);

    this.activitRef = createRef();
    this.state = {
      focused: false,
    };
  }

  get focused() {
    return this.state.focused;
  }
  get editing() {
    const section = this.props.section,
      du = section.deal.currentDealUser;
    const assignable = section.list || section.appendix;
    return (
      this.focused && du && (du.canEdit || (assignable && assignable.assigned && assignable.assigned == du.partyID))
    );
  }
  get proposing() {
    const e = this.editing;
    if (!e) return false;
    //this is safe because we've already verified a current deal user via this.editing().
    else return this.props.section.deal.currentDealUser.role == DealRole.PROPOSER;
  }

  get classRoot() {
    const type = _.get(this.props.section, 'sectiontype', '');

    if (type === SectionType.ITEM) {
      if (_.get(this.parent, 'sectiontype') === SectionType.SCOPE) return 'scope';
      else return 'source';
    } else if ([SectionType.SOURCE, SectionType.LIST].includes(type)) return 'source';
    else if (type === SectionType.PAYMENT) return 'payment';
    else return 'content';
  }

  get canExpand() {
    //only allow accordion expansion on overview and only if there's source content present
    const { section, overviewMode, renderChildren } = this.props;
    return overviewMode && renderChildren && section && section.children.length > 0;
  }

  get className() {
    const { section, linked, readonly, lock, locks, stale, locked } = this.props;
    const { source: expanded } = this.state;

    const deleted = section.deleted;
    const editable = !section.deleted && (this.canEdit || this.canAdmin) && !readonly;
    const discussable = !deleted && !editable && this.canDiscuss;

    return cx(
      `${this.classRoot}-section`,
      { activity: this.focused },
      { linked },
      { 'has-children': section.children && section.children.length > 0 },
      { unordered: section.hideOrder },
      { 'show-bundle': section.deal.isBundle },
      { stale },
      { 'editing-section': this.editing },
      { readonly: readonly || lock || (locks && locks[section.id]) },
      { locked: lock || stale || (locks && locks[section.id]) },
      { deleted: section.deleted },
      { 'can-review': section.deleted && this.canEdit },
      { 'is-editable': editable || section.hasNumberAlignmentOverride },
      { 'locked-step': locked },
      { 'is-discussable': discussable },
      { expanded },
      { 'has-changes': (section.priorVersion || this.hasChanges) && this.editing && !section.isCaption }
    );
  }

  get canEdit() {
    const { section, overviewMode, readonly, appendix } = this.props;
    const deal = section.deal,
      du = deal.currentDealUser;
    // If this section is part of an assignable section type (SCOPE / PAYMENT / LIST),
    // The relevant getters will find a reference to it
    // Since a LIST can be nested inside an APPENDIX (with its own assignment/permissions), that should come first
    const assignable = section.list || section.appendix || appendix;

    return (
      du &&
      (du.canEdit || (assignable && assignable.assigned && assignable.assigned == du.partyID)) &&
      //no summary editing -- only scope items, source and appendix
      [SectionType.ITEM, SectionType.SOURCE, SectionType.APPENDIX, SectionType.PAYMENT].indexOf(section.sectiontype) >
        -1 &&
      //only Scope Items and Payment Items can be edited in overview mode (not contract source)
      (!overviewMode || [SectionType.ITEM, SectionType.PAYMENT].indexOf(section.sectiontype) > -1) &&
      //no editing contract sections in preview
      (!deal.preview || overviewMode) &&
      // && !this.isTitleOnly
      !readonly
    );
  }

  get canDiscuss() {
    return (
      this.props.section.deal &&
      this.props.section.deal.currentDealUser &&
      !this.props.section.deal.currentDealUser.canEdit &&
      this.props.section.todo == 0 &&
      !this.isTitleOnly &&
      (!this.props.locked || this.props.section.activity.length > 0)
    );
  }

  get isTitleOnly() {
    return SectionType.src(this.props.section.sectiontype) && !this.props.section.content;
  }

  get showingActivity() {
    const { showActivity, section } = this.props;
    if (!section || !showActivity || showActivity.length == 0 || showActivity.indexOf(section.id) < 0) return false;
    return true;
  }

  renderStatus() {
    if (this.props.section.status == null) console.log(`No status for ${this.props.section.id}`);
    return this.props.section.status.title.toUpperCase();
  }

  renderCommentAnchor() {
    const { overviewMode, activityMode, section, noActivity, readonly, signed } = this.props;
    const renderCommentAnchor =
      section.deal.commenting &&
      !readonly &&
      !overviewMode &&
      !noActivity &&
      !section.deal.preview && //must be on contract view and not in a preview
      activityMode != 'none' && //comments must not be explicitly hidden
      (section.comments.length > 0 || !signed);

    if (renderCommentAnchor) {
      let anchorClass = `activity-anchor ${section.activityStatus.data}${this.showingActivity ? ' showing' : ''}`;
      if (activityMode != 'none' && section.activityStatus.data == DealStatus.AGREED.data) anchorClass += ' force-show';

      return (
        <div ref={this.activityRef} className={anchorClass} onClick={() => this.toggleActivity(true)}>
          <Icon name="comments" size="large" />
        </div>
      );
    } else return null;
  }

  toggleActivity(show) {
    const { toggleActivity, section } = this.props;
    //showing activity may cause the entire doc (.outer-paper-layout) to shift left
    //so the props call needs to happen first so that the popover is correctly positioned
    if (typeof toggleActivity === 'function') toggleActivity(show, section.id);
  }

  renderActivity(target) {
    const { section, user } = this.props;

    if (!this.showingActivity) return null;

    return <ActivityView log={section} target={target} onHide={() => this.toggleActivity(false)} user={user} />;
  }
}
