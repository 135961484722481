import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay } from 'react-bootstrap';

import { CLAUSE_LENS_PROMPTS } from '@core/models/Lens';

import { Button, ButtonIcon, Dropdown, Icon, MenuItem, Popover, Switch } from '@components/dmp';

@autoBindMethods
export default class LensFilterView extends Component {
  static propTypes = {
    lens: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      config: false,
      selectedFilter: null,
    };

    this.refLens = createRef();
  }

  get filters() {
    const { lens } = this.props;
    return lens.type === 'variable' ? lens.valueFilters : lens.clauseFilters;
  }

  get activeFilters() {
    const { lens, activeLensFilters } = this.props;

    return _.find(activeLensFilters, { id: lens.id });
  }

  get editingActiveFilter() {
    const { selectedFilter } = this.state;
    return !!_.find(this.activeFilters?.filters, { id: selectedFilter?.id });
  }

  reset() {
    this.setState({ config: false, selectedFilter: null });
  }

  displayLabel(filter) {
    const { lens } = this.props;

    return lens.type === 'variable'
      ? filter.valueFilter.displayLabel
      : _.find(CLAUSE_LENS_PROMPTS, { id: filter.id })?.title;
  }

  renderConfig() {
    const { lens, onChange } = this.props;
    const { config, selectedFilter } = this.state;

    return (
      <Overlay show={config} onHide={this.reset} target={this.refLens.current} placement="right" rootClose>
        <Popover
          className="lens-filter popover-lens-filter"
          id={`pop-lens-filter-${lens.id}`}
          title={lens.title}
          closeBtn={true}
          onHide={this.reset}
        >
          <Dropdown
            size="small"
            id="dd-seats"
            onSelect={(selectedFilter) => this.setState({ selectedFilter })}
            block
            title={selectedFilter ? this.displayLabel(selectedFilter) : 'Select lens filter'}
          >
            {_.map(this.filters, (filter, idx) => (
              <MenuItem key={idx} eventKey={filter} disabled={!!_.find(this.activeFilters?.filters, { id: filter.id })}>
                {this.displayLabel(filter)}
              </MenuItem>
            ))}
          </Dropdown>
          <div className="actions">
            <Button
              dmpStyle="link"
              size="small"
              onClick={() => {
                if (this.editingActiveFilter) onChange(lens, selectedFilter, true, true);
                this.reset();
              }}
            >
              Clear
            </Button>
            <Button
              size="small"
              onClick={() => {
                onChange(lens, selectedFilter, true, false);
                this.reset();
              }}
              disabled={!selectedFilter}
            >
              Save
            </Button>
          </div>
        </Popover>
      </Overlay>
    );
  }

  renderActiveFilters(filter) {
    const lensFilter = _.find(this.filters, { id: filter.id });

    return (
      <div
        className="filter-label"
        key={filter.id}
        onClick={() => this.setState({ config: true, selectedFilter: lensFilter })}
      >
        {this.displayLabel(lensFilter)}
      </div>
    );
  }

  render() {
    const { lens } = this.props;
    const { id, title } = lens;

    return (
      <div className="lens-filter" ref={this.refLens}>
        <div
          className={cx('filter-name', { active: !!this.activeFilters })}
          key={id}
          onClick={() => this.setState({ config: true })}
        >
          <div>{title}</div>
          <Icon name="filterExpand" />
        </div>
        {!!this.activeFilters && (
          <div className="filters-applied">{_.map(this.activeFilters.filters, this.renderActiveFilters)}</div>
        )}
        {this.renderConfig()}
      </div>
    );
  }
}
