import React, { Component } from 'react';
import cx from 'classnames';

import _ from 'lodash';

import { HEADER_FOOTER_CONFIG_OPTIONS, HEADER_FOOTER_SUB_SECTION_TYPES } from '@core/enums/SectionType';

const { NO_PAGES, ALL_PAGES, FIRST_PAGE, PAGE_BREAK } = HEADER_FOOTER_CONFIG_OPTIONS;

export default class HeaderFooterBreak extends Component {
  render() {
    const { section } = this.props;
    const { deal, sourceParent } = section;

    const headerFooterConfigKey = sourceParent ? sourceParent.headerFooterConfigKey : section.headerFooterConfigKey;
    const id = sourceParent ? sourceParent.pageBreakID : section.pageBreakID;
    const config = _.find(deal.pageBreakConfig, { id });
    let label;
    const type = section.isTemplateHeader ? 'header' : 'footer';
    const arrow = section.isTemplateHeader ? '▲' : '▼';

    switch (headerFooterConfigKey) {
      case FIRST_PAGE:
        label = `${_.upperFirst(type)}: First page ${type} ${arrow}`;
        break;
      case ALL_PAGES:
        label = `${_.upperFirst(type)}: All pages ${type} ${arrow}`;
        break;
      case PAGE_BREAK:
        label = `${_.upperFirst(type)}: From ${config?.displayName} ${arrow}`;
        break;
      default:
        break;
    }


    return (
      <div
      className={cx(
        'header-footer-break',
        { 'header-break': section.isTemplateHeader },
        { 'footer-break': section.isTemplateFooter },
      )}
        data-cy="header-footer-break"
        style={deal.style.layout.PageMargin.pageBreakMargin}
      >
        <span className="header-footer-break-name">{label}</span>
      </div>
    );
  }
}
