import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { CohereClient } from 'cohere-ai';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl, Modal } from 'react-bootstrap';
import MarkdownRenderer from 'react-markdown-renderer';

import { AI_PROMPTS, PROPOSED_EDITS_KNOWLEDGE_BASE } from '@core/models/AIPrompt';
import Deal from '@core/models/Deal';
import Team from '@core/models/Team';
import { DateFormatter } from '@core/utils';

import { Alert, Button, ButtonIcon, Icon, Loader, Switch } from '@components/dmp';

import VariableView from '@components/VariableView';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import CONFIG from '@root/Config';
import Fire from '@root/Fire';

import LensCheck from './deal/LensCheck';

const { COHERE_KEY } = CONFIG;

//Legacy work
const showVineLens = false;
export const PDF_REVIEW_DEFAULTS = {
  showDocTitle: showVineLens,
  showSections: showVineLens,
  showNumbers: showVineLens,
  showTerms: showVineLens,
  showTitles: showVineLens,
  showHeaders: showVineLens,
  showFooters: showVineLens,
  showMargins: showVineLens,
};

const DOCUMENT_ANALYSIS_OPTIONS = [
  {
    key: 'ocr',
    title: 'OCR (included)',
    test: (deal) => true,
    subtext: 'Document is scanned and indexed',
    isDefault: true,
  },
  {
    key: 'variable',
    title: 'Variable analysis',
    test: (deal) => deal.documentAI && !deal.extracted?.error,
    subtext: '(For structured form data) Finds key value pairs and stores them in variables panel',
    isDefault: false,
  },
  {
    key: 'clause',
    title: 'Clause analysis',
    test: (deal) => deal.documentAI && !deal.extracted?.error && deal.extracted?.sections,
    subtext: 'Extracts key clauses from documents and runs prompts, e.g, compare to original.',
    isDefault: false,
  },
  {
    key: 'scoring',
    title: 'Scoring',
    test: (deal) => deal.hasLenses,
    subtext: 'Calculates a score based on variable values and found clauses within a document',
    isDefault: false,
  },
];

//Active set true for releasable pieces of PDF ingestion (Lenses)
export const PDF_REVIEW_TABS = [
  {
    key: 'summary',
    title: 'Summary',
    active: () => true,
  },
  {
    key: 'lenses',
    title: 'Scorecard',
    active: (deal, user) => deal.hasLenses && deal.lensChecks,
  },
  {
    key: 'terms',
    title: 'Variables',
    active: (deal, user) => deal.documentAI && deal.extracted && !deal.extracted.error,
  },
  {
    key: 'clauses',
    title: 'Clauses',
    active: (deal, user) => deal.documentAI && deal.extracted && !deal.extracted.error && deal.extracted.sections,
  },
  {
    key: 'AI',
    title: 'AI',
    active: (deal, user) => user.isSuper,
  },
];

@autoBindMethods
export default class PDFReview extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    dealTeam: PropTypes.instanceOf(Team).isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      reviewOptions: this.reviewOptions,
      tab: 'summary',
      selectedDefinitions: {},
      results: false,
      selectedClause: null,
      showClauseDifference: false,
      showIssues: false,
      showVariableIssues: false,
      loadingClauseExtraction: false,
      manuallyEditClauseExtraction: false,
      editingClause: null,
      editingVariableValue: null,
      hideNonScoring: false,
      preferredLanguageCheck: null,
      loadingProposedRewrites: false,
      showExtractData: false,
    };
  }

  get showFailedSwitch() {
    const { deal } = this.props;
    const { extracted, lensChecks } = deal;
    let showFailedSwitch = false;

    if (extracted && extracted.sections) {
      _.forEach(extracted.sections, (clause) => {
        if (clause.vitalIDs) {
          const vitalIDs = clause.vitalIDs.split(',');
          _.forEach(vitalIDs, (id) => {
            const check = _.find(lensChecks.checks, ({ lens }) => {
              return lens.id === id;
            });
            if (check?.failedCheck) {
              showFailedSwitch = true;
            }
          });
        }
      });
    }
    return showFailedSwitch;
  }

  async saveManualUpdates() {
    const { deal } = this.props;
    const { selectedClause, editingClause } = this.state;

    try {
      await Fire.updateExtractedClause(deal.dealID, editingClause, selectedClause.name);
      if (selectedClause.lensID)
        await API.call('rerunLensClauseCheck', { dealID: deal.dealID, lensID: selectedClause.lensID });
    } catch (err) {
      console.error(`Failed to queue lens check for deal ${deal.dealID}`);
    }
    this.setState({ editingClause: null, manuallyEditClauseExtraction: false });
  }

  async lensScoring() {
    const { deal } = this.props;

    await API.call('checkDealLenses', { dealID: deal.dealID });
  }

  async humanLoopTest() {
    const { deal } = this.props;

    const OCR = _.values(deal.currentOCR?.content).join('');
    const inputs = { clause_name: 'Terms and Conditions', OCR };

    const project = await API.call('humanloop', { projectID: 'pr_jM77mOzrWn7QKKyLmtTQ8', inputs });
  }

  async proposesEdits() {
    const { deal } = this.props;

    this.setState({ proposedRewrites: null });

    const prompt = `You are tasked with advising Bandito concerning the language in the attached contract titled "${deal.dealID}" Your goal is to enhance its favorability for Bandito based on the standards set out in the "Terms and Conditions Knowledge Base." Please follow these steps:
    - Careful Reading: Read the entire ${deal.dealID} contract thoroughly. Do not skip any clauses.
    - Identify Improvements: Identify clauses that can be improved to increase favorability for Bandito.
    - Draft Revisions: Provide a set of proposed revisions. Each revision should be clearly marked down in markdown format. Example format for revisions is provided below.
    - Citations: Include citations from the "Terms and Conditions Knowledge Base" to justify your suggested amendments.
    - Organize by Themes: Organize your proposed revisions by legal themes such as Liability, Indemnification, Payment Terms, etc.
    - Review: Ensure all revisions are clearly articulated and thoroughly justified. Review your work for accuracy before submission.
    
    Example of Revision:
    - **Original Clause:**
    > Bandito shall provide to Miron the services ("Services") set out in one or more PSPSA’s to be issued by Miron.
    - **Suggested Revision**:
    > Bandito agrees to provide Miron with the services ("Services") as detailed in each Project Specific Professional Services Agreement (PSPSA), which Miron will outline and issue.
    - **Justification**:
    According to the "Terms and Conditions Knowledge Base," Section 3.2 on 'Service Obligations', it's recommended to clearly define the scope of services in the initial agreement to avoid ambiguities (page 5, "Client Responsibilities").
    - **Location for Revision**:
    Page 1, Paragraph 1, Lines 3-4`;

    const OCR = _.values(deal.currentOCR?.content).join('');

    const documents = [
      { title: 'Terms and Conditions Knowledge Base.', snippet: PROPOSED_EDITS_KNOWLEDGE_BASE },
      { title: deal.dealID, snippet: OCR },
    ];

    const preamble = `## Task & Context
    You are a large language model designed to help lawyers with contract analysis. Follow the all the instructions in the prompt to best serve lawyers reading your response.`;

    this.setState({ loadingProposedRewrites: true, showExtractData: true });

    const cohere = new CohereClient({
      token: COHERE_KEY,
    });

    const params = {
      preamble,
      model: 'command-r-plus',
      message: prompt,
      temperature: 0.3,
      max_tokens: 4000,
    };

    if (documents) params.documents = documents;

    const chatStream = await cohere.chatStream(params);

    for await (const message of chatStream) {
      if (message.eventType === 'text-generation') {
        let streamedText;
        if (!this.state.proposedRewrites) {
          streamedText = message.text;
          this.setState({ loadingProposedRewrites: false });
        } else streamedText = this.state.proposedRewrites + message.text;
        this.setState({ proposedRewrites: streamedText });
      }
    }
  }

  async rerunClauseExtraction() {
    const { deal } = this.props;
    const { selectedClause } = this.state;

    this.setState({ loadingClauseExtraction: true });
    const OCR = _.values(deal.currentOCR?.content).join('');

    try {
      const projectID = _.find(AI_PROMPTS, { name: 'Clause Extraction' }).project_id;
      const inputs = {
        clause_name: selectedClause.name,
        OCR,
      };
      const response = await API.call('humanloop', { projectID, inputs });
      const output = response.text;
      await Fire.updateExtractedClause(deal.dealID, output ? output : 'Not Found', selectedClause.name);
      if (selectedClause.lensID)
        await API.call('rerunLensClauseCheck', { dealID: deal.dealID, lensID: selectedClause.lensID });
    } catch (err) {
      console.log(err);
    }
    this.setState({ loadingClauseExtraction: false });
  }

  async runPreferedLanguageCheck() {
    const { deal } = this.props;
    const { selectedClause } = this.state;
    const { extracted } = deal;

    this.setState({ loadingClauseDocumentComparison: true, showExtractData: true });

    const standardClause = extracted.sections[selectedClause.name].standardClause;

    const OCR = _.values(deal.currentOCR?.content).join('');

    const projectID = _.find(AI_PROMPTS, { name: 'Missing Preferred Language' }).project_id;
    const inputs = {
      clause_name: selectedClause.name,
      OCR,
      standard_terms: standardClause,
    };

    const response = await API.call('humanloop', { projectID, inputs });
    const output = response.text;

    this.setState({ clauseDocumentComparison: output });
    this.setState({ loadingClauseDocumentComparison: false });
  }

  async runRedlining() {
    const { deal } = this.props;
    const { selectedClause } = this.state;
    const { extracted } = deal;

    this.setState({ loadingClauseRedlining: true, showExtractData: true });

    const standardClause = extracted.sections[selectedClause.name].standardClause;
    const extractedClause = extracted.sections[selectedClause.name].extractedClause;

    const projectID = _.find(AI_PROMPTS, { name: 'Redlining' }).project_id;
    const inputs = {
      clause_name: selectedClause.name,
      extracted_clause: extractedClause,
      standard_terms: standardClause,
    };
    const response = await API.call('humanloop', { projectID, inputs });

    const output = response.text;

    this.setState({ loadingClauseRedlining: false });
    this.setState({ clauseRedlines: output });
  }

  renderDefinition(variable, idx) {
    const { editingVariableValue } = this.state;
    const { deal, user } = this.props;
    const { needsReview, extractedValue, variableName } = variable;

    const v = deal.variables[variableName];
    //Two cases to check for
    //1: User reviewed and approved a empty extracted value.
    // This case is true if we have toggled off needsReview and both values are still null or undefined.
    //2: The user reviewed or editied the extracted value.
    //This is a simple value comaprison.
    const humanReviewed = !extractedValue && !v.value && !needsReview ? true : v.value !== extractedValue;

    if (needsReview) {
      return (
        <DealPanelItem borderBottom className="deal-panel-item-type-danger" active={true}>
          <div className="definition" key={idx}>
            <div className={cx('term-definition-title')}>
              <b>{variableName}</b>
            </div>
            <VariableView
              variable={v}
              section={deal.root}
              text={`[#${v.name}]`}
              markReview
              user={user}
              onSave={(variable) => Fire.markVariableReviewed(variable)}
              deal={deal}
            />
          </div>
        </DealPanelItem>
      );
    } else {
      return (
        <DealPanelItem borderBottom>
          <div className="definition" key={idx}>
            <div className={cx('term-definition-title')}>
              <b>{variableName}</b>
            </div>
            <div className="term-info-container">
              {editingVariableValue !== v.name && (
                <div className="term-value">
                  {humanReviewed ? (
                    <TooltipButton tip="Human-reviewed">
                      <Icon name="fieldsEdit" size="default" />
                    </TooltipButton>
                  ) : (
                    <TooltipButton tip="AI extracted">
                      <Icon name="aiAuto" size="default" />
                    </TooltipButton>
                  )}
                  <a
                    onClick={() =>
                      this.setState({
                        editingVariableValue: v.name,
                      })
                    }
                  >
                    {humanReviewed ? (v.value ? `"${v.value}"` : '(empty)') : `"${extractedValue}"`}
                  </a>
                </div>
              )}
            </div>
            {editingVariableValue === v.name && this.editVariable(v, extractedValue)}
          </div>
        </DealPanelItem>
      );
    }
  }

  editVariable(variable, extractedValue) {
    const { deal, user } = this.props;

    return (
      <VariableView
        variable={variable}
        section={deal.root}
        text={`[#${variable.name}]`}
        editExtractedValue
        onCancel={() => this.setState({ editingVariableValue: null })}
        user={user}
        onSave={(variable) => {
          if (variable.value !== extractedValue) Fire.markVariableReviewed(variable);
          this.setState({ editingVariableValue: null });
        }}
        deal={deal}
      />
    );
  }

  renderClauses(clause, idx) {
    return (
      <DealPanelItem borderBottom key={idx} className="clause-summary-container">
        <div
          className="clause-name"
          onClick={() =>
            this.setState({ selectedClause: { name: clause.clauseName, failedCheck: false, lensID: clause.lensID } })
          }
        >
          {clause.clauseName}
        </div>
      </DealPanelItem>
    );
  }

  renderComparisonModal() {
    const {
      clauseRedlines,
      loadingClauseRedlining,
      loadingClauseDocumentComparison,
      clauseDocumentComparison,
      showExtractData,
      loadingProposedRewrites,
      proposedRewrites,
    } = this.state;

    const loading = loadingClauseRedlining || loadingClauseDocumentComparison || loadingProposedRewrites;
    const content = clauseRedlines || clauseDocumentComparison || proposedRewrites;

    const title =
      loadingClauseRedlining || clauseRedlines
        ? 'Redlined extracted clause based on standard terms'
        : loadingClauseDocumentComparison || clauseDocumentComparison
        ? 'Compare to preferred language (full contract)'
        : 'Proposed Rewrites';

    return (
      <Modal
        dialogClassName="extracted-data-modal"
        show={showExtractData}
        onHide={() => this.setState({ showExtractData: false, clauseDocumentComparison: null, clauseRedlines: null })}
      >
        <Modal.Header closeButton>
          <span className="headline">{title}</span>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loader />}
          {content && <MarkdownRenderer markdown={content} />}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { deal, container, show, onHide, target, title, user } = this.props;
    const {
      tab,
      selectedClause,
      showClauseDifference,
      showIssues,
      showVariableIssues,
      loadingClauseExtraction,
      manuallyEditClauseExtraction,
      editingClause,
      hideNonScoring,
      loadingProposedRewrites,
      loadingClauseRedlining,
      loadingClauseDocumentComparison,
    } = this.state;
    const { lensChecks, extracted, documentAI, hasLenses, isProcessingAI } = deal;
    const analyzedOn = extracted ? new Date(parseInt(extracted.analyzedOn)) : null;
    const formattedDate = analyzedOn
      ? `${DateFormatter.ymd(analyzedOn, '-')} @ ${DateFormatter.time(analyzedOn)}`
      : null;

    const variablesForReview = extracted?.variables ? _.filter(extracted.variables, { needsReview: true }) : [];

    const terms = showVariableIssues && variablesForReview.length > 0 ? variablesForReview : extracted?.variables;

    return (
      <DealPanel id={'lens'} onHide={onHide} show={show} target={target} title={title} container={container}>
        {this.renderComparisonModal()}
        <div className="filter-bar review-tabs">
          <ButtonGroup className="tabs">
            {PDF_REVIEW_TABS.map((tabDef) => {
              if (tabDef.active(deal, user))
                return (
                  <Button
                    dmpStyle="link"
                    active={tabDef.key === tab}
                    onClick={() => this.setState({ tab: tabDef.key, selectedClause: null })}
                    data-cy={`${tabDef.key}-tab`}
                    disabled={isProcessingAI}
                    key={tabDef.key}
                  >
                    {tabDef.key === 'terms' && variablesForReview.length > 0 && <div className="needsReview" />}
                    {tabDef.title}
                  </Button>
                );
            })}
          </ButtonGroup>
        </div>
        {isProcessingAI && (
          <div className="panel-scroll">
            <DealPanelItem borderBottom>
              <div className="analysis-loading">
                <Loader /> AI analysis is in progress, please wait...
              </div>
            </DealPanelItem>
          </div>
        )}
        {!isProcessingAI && (
          <div className="panel-scroll">
            {tab === 'summary' && !selectedClause && (
              <>
                {extracted && extracted.error && (
                  <div className="panel-scroll">
                    <DealPanelItem borderBottom>
                      <div className="analysis-loading">
                        <Alert dmpStyle="danger">{extracted.error}</Alert>
                      </div>
                    </DealPanelItem>
                  </div>
                )}
                {extracted && !extracted.error && (
                  <DealPanelItem borderBottom>
                    <>
                      <div className="summary-header">AI analysis</div>

                      <div className="summary-block">
                        Date analyzed
                        <span className="summary-sub-block">{formattedDate}</span>
                      </div>

                      <div className="summary-block">
                        Pages in document
                        <span className="summary-sub-block">{extracted.pages || deal.currentOCR.pages}</span>
                      </div>

                      <div className="summary-block">
                        Variable(s) needing review
                        <span className="summary-sub-block">{variablesForReview.length}</span>
                      </div>

                      {lensChecks && (
                        <div className="summary-block">
                          Risk score
                          <span className="summary-sub-block">{`+${lensChecks.totalRisk}`}</span>
                        </div>
                      )}
                    </>
                  </DealPanelItem>
                )}
                <DealPanelItem borderBottom>
                  <>
                    <div className="summary-header">Services used</div>

                    {_.map(DOCUMENT_ANALYSIS_OPTIONS, (option, idx) => {
                      if (option.test(deal)) {
                        return (
                          <div className={cx('analysis-option-container', { isDefault: option.isDefault })} key={idx}>
                            <Icon name="ai" className={cx('icon-ai', { isDefault: option.isDefault })} />
                            <div className="analysis-summary" data-cy="analysis-summary">
                              <span className="summary-title">{option.title}</span>
                              <div className="summary-subtext">{option.subtext}</div>
                            </div>
                            <div className="spacer" />
                            <div className={cx('active', { isDefault: option.isDefault })}>Active</div>
                          </div>
                        );
                      }
                    })}
                  </>
                </DealPanelItem>
              </>
            )}
            {tab === 'terms' && !selectedClause && documentAI && extracted && (
              <>
                <DealPanelItem borderBottom>
                  <div className="terms-header-container">
                    <div className="terms-count">{`Extracted (${_.size(extracted.variables)})`}</div>
                    {variablesForReview.length > 0 && (
                      <div className="terms-filter">
                        <Switch
                          id="terms-filter-switch"
                          checked={showVariableIssues}
                          onChange={() => this.setState({ showVariableIssues: !showVariableIssues })}
                          className="terms-filter-switch"
                          size="small"
                        >
                          {`Needs review (${variablesForReview.length})`}
                        </Switch>
                      </div>
                    )}
                  </div>
                </DealPanelItem>
                {_.map(terms, (variable, idx) => this.renderDefinition(variable, idx))}
              </>
            )}
            {tab === 'clauses' && !selectedClause && documentAI && (
              <>
                <DealPanelItem borderBottom>
                  <div className="clause-issue-switch-container">
                    <div className="issue-switch-title">{`Extracted (${_.size(extracted.sections)})`}</div>
                    {hasLenses && this.showFailedSwitch && (
                      <Switch
                        id="show-clause-issues"
                        checked={showIssues}
                        onChange={() => {
                          this.setState({ showIssues: !showIssues });
                        }}
                        className="show-clause-issues"
                        size="small"
                      >
                        {`Show failed vitals`}
                      </Switch>
                    )}
                  </div>
                </DealPanelItem>
                {_.map(extracted.sections, (clause, idx) => this.renderClauses(clause, idx))}
                {user.isSuper && (
                  <DealPanelItem borderBottom>
                    {!loadingProposedRewrites && (
                      <Button size="small" block onClick={this.proposesEdits}>
                        Propose Edits
                      </Button>
                    )}
                    {loadingProposedRewrites && (
                      <span onClick={() => this.setState({ showExtractData: true })}>
                        <Loader size="default" />
                      </span>
                    )}
                  </DealPanelItem>
                )}
              </>
            )}

            {tab === 'lenses' && lensChecks && !selectedClause && (
              <>
                <DealPanelItem borderBottom>
                  <Switch
                    id="toggle-non-scoring"
                    checked={!hideNonScoring}
                    size="small"
                    onChange={() => this.setState({ hideNonScoring: !hideNonScoring })}
                  >
                    Show zero scores
                  </Switch>
                </DealPanelItem>
                <DealPanelItem borderBottom>
                  <LensCheck lensChecks={lensChecks} hideNonScoring={hideNonScoring} />
                </DealPanelItem>
              </>
            )}

            {tab === 'AI' && user.isAdmin && !selectedClause && (
              <>
                <DealPanelItem borderBottom>
                  <Button dmpStyle="link" onClick={this.lensScoring} data-cy={`ai-extraction-btn`}>
                    Re-Run Lens Scoring
                  </Button>
                </DealPanelItem>
                <DealPanelItem borderBottom>
                  <Button dmpStyle="link" onClick={this.humanLoopTest} data-cy={`ai-extraction-btn`}>
                    Human Loop Test
                  </Button>
                </DealPanelItem>
              </>
            )}
            {selectedClause && (
              <>
                <DealPanelItem borderBottom>
                  <div className="clause-comparison-header">
                    <ButtonIcon
                      icon="chevronLeft"
                      onClick={() => this.setState({ selectedClause: null })}
                      data-cy="btn-bundle"
                      size="default"
                    />
                    <span className="compare-clause-title">Back</span>
                  </div>
                </DealPanelItem>
                {/*<DealPanelItem borderBottom>
                  <div className="clause-diff-switch-container">
                    <div className="diff-switch-title">Show differences</div>
                    <Switch
                      id="show-clause-diff"
                      checked={showClauseDifference}
                      onChange={() => {
                        this.setState({ showClauseDifference: !showClauseDifference });
                      }}
                      className="show-clause-diff"
                      size="small"
                    />
                  </div>
                </DealPanelItem>
                    */}
                {!showClauseDifference && (
                  <DealPanelItem borderBottom>
                    <div className="clause-display">
                      <div className="clause-display-container">
                        <div className="clause-title">Extracted clause</div>
                        <div className="action-container">
                          <div className="left">
                            {user.isSuper && !loadingClauseRedlining && (
                              <TooltipButton tip="Generate redlines" placement="left">
                                <ButtonIcon
                                  data-cy="undo"
                                  icon="redlining"
                                  className="undo"
                                  size="default"
                                  disabled={manuallyEditClauseExtraction || loadingClauseDocumentComparison}
                                  onClick={this.runRedlining}
                                />
                              </TooltipButton>
                            )}
                          </div>
                          <div className="left">
                            {user.isSuper && !loadingClauseDocumentComparison && (
                              <TooltipButton tip="Compare to preferred language (full contract)" placement="left">
                                <ButtonIcon
                                  data-cy="undo"
                                  icon="document"
                                  className="undo"
                                  size="default"
                                  disabled={manuallyEditClauseExtraction || loadingClauseRedlining}
                                  onClick={this.runPreferedLanguageCheck}
                                />
                              </TooltipButton>
                            )}
                          </div>
                          <div className="left">
                            {!loadingClauseExtraction && (
                              <TooltipButton tip="Re-run clause extraction" placement="left">
                                <ButtonIcon
                                  data-cy="undo"
                                  icon="undo"
                                  className="undo"
                                  size="default"
                                  disabled={manuallyEditClauseExtraction}
                                  onClick={this.rerunClauseExtraction}
                                />
                              </TooltipButton>
                            )}
                            {loadingClauseExtraction && (
                              <TooltipButton tip="Re-running clause extraction" placement="left">
                                <span>
                                  <Loader size="default" />
                                </span>
                              </TooltipButton>
                            )}
                          </div>
                          <div className="right">
                            {!manuallyEditClauseExtraction && (
                              <TooltipButton tip="Manually edit clause extraction" placement="left">
                                <ButtonIcon
                                  data-cy="draft"
                                  icon="edit"
                                  className="draft"
                                  size="default"
                                  disabled={loadingClauseExtraction}
                                  onClick={() =>
                                    this.setState({
                                      manuallyEditClauseExtraction: true,
                                      editingClause: extracted.sections[selectedClause.name].extractedClause.trim(),
                                    })
                                  }
                                />
                              </TooltipButton>
                            )}
                            {manuallyEditClauseExtraction && (
                              <TooltipButton tip="Cancel edits" placement="left">
                                <ButtonIcon
                                  data-cy="close"
                                  icon="close"
                                  className="close"
                                  size="default"
                                  onClick={() =>
                                    this.setState({ manuallyEditClauseExtraction: false, editingClause: null })
                                  }
                                />
                              </TooltipButton>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={cx('clause-text-container', { failedCheck: selectedClause.failedCheck })}>
                        {!manuallyEditClauseExtraction && (
                          <div className="clause-text">{extracted.sections[selectedClause.name].extractedClause}</div>
                        )}
                        {manuallyEditClauseExtraction && (
                          <>
                            <FormControl
                              componentClass="textarea"
                              value={editingClause}
                              onChange={(e) => this.setState({ editingClause: e.target.value })}
                            />
                            <div className="edit-actions">
                              <Button
                                className="save-manual-edit"
                                onClick={this.saveManualUpdates}
                                dmpStyle="link"
                                size="small"
                              >
                                Save
                              </Button>
                              <Button
                                className="save-manual-edit"
                                onClick={() =>
                                  this.setState({ manuallyEditClauseExtraction: false, editingClause: null })
                                }
                                dmpStyle="link"
                                size="small"
                              >
                                Cancel
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </DealPanelItem>
                )}
                {showClauseDifference && (
                  <DealPanelItem borderBottom>
                    <div className="clause-display">
                      <div className="clause-text-container">
                        <div className="clause-text">Hello world</div>
                      </div>
                    </div>
                  </DealPanelItem>
                )}
              </>
            )}
          </div>
        )}
      </DealPanel>
    );
  }
}
