import { cloneDeep, compact, find, forEach, get, isNil, map, filter } from 'lodash';

import { rxVariable } from '@core/models/Content';

import { States } from '../enums';
import { AI_PROMPT as TIMELINE_AI_PROMPT_TEXT } from './TimelineEvent';
import { EXTERNAL_TYPES, ValueType } from './Variable';

export const PROPOSED_EDITS_KNOWLEDGE_BASE = `# Bandito Consultants Corp. Terms and Conditions Knowledge Base
  ## Standard of Care
  **Definition:** Services provided under this agreement will adhere to generally accepted professional practices.
  **Relevant Text:**
  - "Services provided by Bandito Consultants Corp. (hereinafter referred to as 'Bandito') under this Agreement will be performed in accordance with generally accepted professional practices."
  - "The services will be performed in a manner consistent with the level of care and skill ordinarily exercised by members of the same profession currently practicing under similar circumstances in the same or similar location."
  - "Under no circumstances shall any other representation (express or implied) or any type of warranty or guarantee be included or intended by Bandito during the completion of its services under this Agreement, (hereinafter the 'Standard of Care')."
  **Label:** standard_of_care
  
  ## Client Responsibilities
  **Definition:** Clients are expected to provide accurate documentation and inform Bandito of any updates or changes.
  **Relevant Text:**
  - "Bandito shall be entitled to rely on the accuracy of documentation presented to it by Client and/or Client’s legal agents."
  - "In the event of updates or changes to any documentation provided to Bandito in furtherance of its services, the Client is responsible for advising Bandito’s personnel of such updates or changes in writing."
  **Label:** client_responsibilities
  
  ## Additional Services
  **Definition:** Services that extend beyond the agreed-upon scope require amendments or change orders.
  **Relevant Text:**
  - "When additional services beyond the defined scope of work are requested, an amendment or change order will be prepared by Bandito and approved by the Client prior to commencing work."
  - "Client’s approval by email or payment of proposed additional services shall be deemed binding."
  **Label:** additional_services
  
  ## Compensation
  **Definition:** Payment terms for services provided, including the applicable rates and schedules.
  **Relevant Text:**
  - "Services provided by Bandito on a time and material basis shall be performed in accordance with Bandito’s current fiscal year Standard Hourly Rate Schedule in effect at the time of performance."
  - "This schedule is updated yearly and is available upon request."
  **Label:** compensation
  
  ## Performance
  **Definition:** Details on how and where the services will be performed, including the utilization of resources.
  **Relevant Text:**
  - "Bandito may use any office or individual in the completion of services required for the Project."
  - "Bandito shall perform work pursuant to an agreed-upon schedule and consistent with the orderly progress inherent in the Standard of Care."
  **Label:** performance
  ## Billing and Payment
  **Definition:** Terms detailing the invoicing and payment procedures, including timelines and responsibilities.
  **Relevant Text:**
  - "The Client agrees to pay Bandito for all services performed and all costs incurred."
  - "Invoices for Bandito’s services shall be submitted either upon completion of such services or on a monthly basis."
  - "Payment of any invoice indicates Client’s acceptance of this Agreement, these Terms & Conditions, and satisfaction with the Bandito’s services."
  **Label:** billing/payment
  
  ## Indemnification
  **Definition:** Conditions under which one party agrees to protect the other from certain losses and liabilities.
  **Relevant Text:**
  - "The Client agrees, to the fullest extent permitted by law, to indemnify and hold harmless Bandito, its directors, employees, and agents against claims, damages, liabilities, and costs arising from and in proportion to the negligent acts or failure to act of Client and its directors, employees, and agents."
  - "Bandito agrees, to the fullest extent permitted by law, to indemnify and hold harmless the Client, its directors, employees and agents against claims, damages, liabilities, and costs arising from and in proportion to the negligent acts or failure to act of Bandito and its directors, employees, and agents."
  **Label:** indemnification
  
  ## Insurance
  **Definition:** Requirements and standards for maintaining insurance coverages during the duration of the project.
  **Relevant Text:**
  - "Bandito shall obtain and maintain the following insurance coverages: Commercial General Liability, Automobile Liability, Umbrella/Excess Liability, Workers Compensation/Employer’s Liability, and Professional Liability."
  - "Certificates of insurance will be provided to the Client upon request."
  **Label:** insurance
  
  ## Assignment
  **Definition:** Terms concerning the transfer, subletting, or assigning of rights or duties under the agreement.
  **Relevant Text:**
  - "Neither party to this Agreement shall transfer, sublet, or assign any rights or duties under or interest in this Agreement, including, but not limited to, monies that are due or monies that may be due, without the prior written consent of the other party."
  - "Subcontracting to subconsultants, normally contemplated by Bandito as a generally accepted business practice, shall not be considered an assignment for purposes of this Agreement."
  **Label:** assignment
  
  ## Dispute Resolution
  **Definition:** Processes and procedures for resolving disagreements or disputes arising from the agreement.
  **Relevant Text:**
  - "Any claims or disputes between the Client and Bandito arising out of the Services to be provided by Bandito or this Agreement shall be subject to discussions for informal resolution."
  - "If no informal resolution is achieved within 14 business days, the Parties agree to submit the matter to non-binding mediation."
  **Label:** dispute_resolution
  
  ## Construction Means and Methods
  **Definition:** Terms specifying responsibilities concerning construction methods and safety measures.
  **Relevant Text:**
  - "Bandito shall not be responsible for, nor have control over or charge of, construction means, methods, sequences, techniques, or procedures, or for any health or safety precautions."
  - "Neither the client nor Bandito is responsible for damages or delays caused by events beyond their control, such as acts of God, strikes, walkouts, accidents, or government acts."
  **Label:** construction_means_and_methods
  ## Construction Observation
  **Definition:** Details concerning the observation services provided during construction and the responsibilities associated.
  **Relevant Text:**
  - "When Bandito does not explicitly provide construction observation services within its written scope of work, it is agreed that the professional services of Bandito do not extend to or include the review or site observation of the contractor's work, performance, or pay request approval."
  - "During construction, the Client assumes the role of the engineer and will hold harmless Bandito for the contractor's performance or the failure of the contractor's work to conform to the design intent and the contract documents."
  **Label:** construction_observation
  
  ## Project Signs
  **Definition:** Requirements for signage at the project site and acknowledgment in publications.
  **Relevant Text:**
  - "Project signs displayed at the construction site shall include 'Bandito' as the Engineer."
  - "Articles for publication regarding this project shall acknowledge Bandito as the Civil, Structural, Mechanical, Electrical and/or Technology Engineer, as applicable."
  **Label:** project_signs
  
  ## Adjustments, Changes, or Additions
  **Definition:** Protocols for handling adjustments, changes, or additions during the project.
  **Relevant Text:**
  - "It is understood that adjustments, changes, or additions may be necessary during construction."
  - "A contingency fund shall be maintained until construction is completed to pay for field changes, adjustments, or increased scope items."
  **Label:** adjustments_changes_or_additions
  
  ## Ownership
  **Definition:** Ownership of intellectual property, including drawings, specifications, and other work products.
  **Relevant Text:**
  - "All drawings, specifications, BIM and other work product of Bandito developed for this Project are instruments of service owned by Bandito."
  - "Client agrees to defend, indemnify and hold harmless Bandito for all claims, damages and expenses, including reasonable attorney’s fees, arising out of unauthorized use of the Bandito’s instruments of service."
  **Label:** ownership
  
  ## Electronic Files
  **Definition:** Terms regarding the use and distribution of electronic files and documents.
  **Relevant Text:**
  - "The Client hereby grants permission for Bandito to use electronic background information produced by the Client in the completion of the project."
  - "The Client also grants permission to Bandito to release such documents electronically to Client, contractors, and vendors as required in the execution of the project."
  **Label:** electronic_files
  
  ## Employment
  **Definition:** Employment restrictions related to hiring employees from either party during and after the contract period.
  **Relevant Text:**
  - "For the duration of this contract, plus six (6) months from the date of final payment received, neither Bandito nor Client, nor their respective agents, will offer employment or contact any person for such purposes who is or was employed by Bandito, Client or their agents for the period of performance of this contract."
  **Label:** employment
  
  ## Termination
  **Definition:** Conditions under which the agreement can be terminated and the consequences thereof.
  **Relevant Text:**
  - "Either party may terminate this Agreement due to the other party’s material breach of this Agreement upon providing a ten (10) day written notice to the breaching party and an opportunity of at least three (3) business days to cure."
  - "Upon termination, payment is required in full for all services rendered and expenses incurred through the date of termination."
  **Label:** termination
  
  ## Survivability
  **Definition:** Clauses that remain in effect even after the termination or expiration of the agreement.
  **Relevant Text:**
  - "In the event any provisions of this agreement shall be held to be invalid and unenforceable, the remaining provisions shall be valid and binding upon the parties."
  - "One or more waivers by either party of any provision, term, condition, or covenant shall not be construed by the other party as a waiver of a subsequent breach of the same by the other party."
  **Label:** survivability
  
  ## Limitation of Liability
  **Definition:** Limits on the liability of either party for losses related to the agreement.
  **Relevant Text:**
  - "It is agreed that the Maximum Aggregate Liability of Bandito arising out of or related to this Agreement and for all work performed on this project, whether based in contract or tort, in law or equity or for negligent acts, errors, or omissions, and all claims, losses, costs, damages, cost of defense, or expenses from any cause, including Client, Contractors, and Attorney fees, will be limited to the greater of the compensation actually paid to Bandito for all work performed under this Agreement or $100,000."
  **Label:** limitation_of_liability
  
  ## Equal Employment Opportunity / Rights Under Federal Labor Laws
  **Definition:** Compliance requirements with federal labor laws regarding non-discrimination and affirmative action.
  **Relevant Text:**
  - "Bandito and Client shall abide by the requirements of 41 CFR 60-1.4(a), 60-300.5(a), 60-741.5(a) and Appendix A of Subpart A of 29 CFR 471 (as may be updated or amended)."
  - "These regulations prohibit discrimination against qualified individuals based on their status as protected veterans or individuals with disabilities and prohibit discrimination against all individuals based on their race, color, religion, sex, or national origin."
  **Label:** equal_employment_opportunity_rights_under_federal_labor_laws
  
  ## Other
  **Definition:** Miscellaneous provisions that do not necessarily fit into other categories but are important to the terms of the agreement.
  **Relevant Text:**
  - "Pursuant to section 558.0035 Florida statutes when applicable, Bandito is the responsible party for the professional services it agrees to provide under this agreement."
  - "No individual professional employee, agent, director, officer, or principal may be individually liable for negligence arising out of this contract."
  **Label:** other
  
  ## Force Majeure
  **Definition:** Conditions under which neither party will be held responsible for failure to perform their obligations under the agreement due to events beyond their control.
  **Relevant Text:**
  - "Except as hereinafter provided, no delay or failure in performance of Bandito shall constitute a default under this Agreement if and to the extent the delay or failure is caused by Force Majeure."
  - "Force Majeure means any event beyond the control of Bandito to perform its obligations and which Bandito is unable to prevent, including without limitation, the combined action of workers, strikes, embargoes, fire, acts of terrorism, epidemics, explosions and other catastrophes, casualties, a moratorium on construction, delays in transportation, governmental delays in granting permits or approvals, changes in laws, expropriation or condemnation of property, governmental actions, unavailability or shortages of materials, national emergency, war, acts of terrorism, cyber-attacks, civil disturbance, floods, unusually severe weather conditions or other acts of God or public enemy."
  **Label:** force_majeure
  
  ## Hazardous Environmental Conditions
  **Definition:** Terms related to the management of hazardous or environmental conditions encountered during the project.
  **Relevant Text:**
  - "Unless expressly stated in writing, Bandito does not provide assessments of the existence or presence of any hazardous or other environmental conditions or environmental contaminants or materials."
  - "Client shall inform Bandito of any and all known Hazardous Environmental Conditions before services are provided involving or affecting them."
  - "If unknown Hazardous Environmental Conditions are encountered, Bandito will notify the Client and, as appropriate, government officials of such conditions."
  **Label:** hazardous_environmental_conditions
  
  ## Buried Utilities
  **Definition:** Responsibilities and liabilities concerning the location and damage of buried utilities and subterranean structures.
  **Relevant Text:**
  - "Client shall be responsible for designating the location of all utility lines and subterranean structures within the property lines of the Project."
  - "Client agrees to waive any claim against Bandito and to defend, indemnify and hold Bandito harmless for any claim or liability for injury or loss arising from Bandito or other persons encountering utilities or other manmade objects that were not brought to the Bandito’s attention or which were not properly located on the plans furnished to Bandito."
  **Label:** buried_utilities
  
  ## Boundary Conflict
  **Definition:** Procedures and actions to be taken in case of discrepancies or conflicts in boundary determinations during the project.
  **Relevant Text:**
  - "Boundary determinations occasionally disclose unseen or unknown conflicts between the record documents and the location of physical improvements."
  - "Upon discovery of any latent or patent ambiguity, uncertainty, or dispute disclosed by the records or by placement of the boundaries on the ground, work on the boundary survey will be suspended and you will be immediately notified."
  **Label:** boundary_conflict`;

export const DEMANDS_AI_PROMPT = {
  system: `You are an experienced personal injury attorney who is an expert in writing demand letters for law firms. Specifically, you are a specialized drafting consultant for law firms. You are given an example or examples of a law firm's customary language for a section of their demand letters from their past cases. Then, you are given details of a new case, and you use the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples to draft the corresponding section for a demand letter reflecting the details of the new case. The sections that you draft apply the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples but use the details of the new case to create an effective section of the demand letter for the law firm.
  When you draft a section, the section should emulate the style, format, tone, voice, purpose, structure, word choice, and types of information included in the example sections, which are placed in the <example> </example> XML tags.
  If an item of case details begins with "[+fv", consider that entry as indicating that there is no information for that item, and that such item should be omitted from the section and not considered during the drafting of the section. "[+fv" is appearing because there was nothing available to be pulled from the data set for that item of case details.
  Read the details and the examples carefully before drafting the section because the section you draft will reflect the style, format, tone, voice, purpose, structure, and word choice of the examples and the facts of the details.
  Only include the details in your draft which are relevant for the particular section being drafted, which you determine as relevant through a comprehensive review of the examples and the types of information which they discuss. If a type of information is included with the details, but the examples do not reference or discuss that type of information, do NOT include it in your section.`,
  user: `Carefully and thoroughly review the details placed in the <details></details> XML tags and the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples placed in the <example></example> XML tags.
  Referencing the details placed in the <details></details> XML tags and the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples placed in the <example></example> XML tags, draft the corresponding section of the demand letter.
  Think step by step within <thinking></thinking> XML tags. Then, draft the section within <draft></draft> XML tags.
  For the factual details of the incident, use ONLY the details placed in the <details></details> XML tags, and do not use any of the facts from the examples. Refer to the examples ONLY for tailoring the style, format, tone, voice, purpose, structure, word choice, and types of information of the section and NOT for the facts, details, or information of the incident.`,
};

export const AI_PROMPTS = [
  { name: 'Clause Extraction', project_id: 'pr_jM77mOzrWn7QKKyLmtTQ8' },
  { name: 'Has Additional Terms', project_id: 'pr_LewgWQXeqSTsyiyUHbLLN' },
  { name: 'Missing Preferred Language', project_id: 'pr_iAAsHWEXk7XG1mTtHxN1s' },
  { name: 'Redlining', project_id: 'pr_YtKE9o3BZ0tSeLT6sSqWI' },
  { name: 'Variable Extraction', project_id: 'pr_hz85ssy3FGV97Cmeymj97' },
];

export const AI_VARIABLE_FORMATS = [
  { type: ValueType.DATE, format: () => 'Format your response as MM/DD/YYYY.' },
  {
    type: ValueType.STATE,
    format: () => `Your response must be one of the following options: ${States.join(', ')}`,
  },
  {
    type: ValueType.NUMBER,
    format: () => 'Your value must be a valid number.',
  },
  {
    type: ValueType.PERCENT,
    format: () => 'Your value must be a valid number.',
  },
  {
    type: ValueType.CURRENCY,
    format: () => 'Your value must be a valid number.',
  },
];

export const AI_ENGINES = [
  {
    title: 'Vinnie AI',
    key: 'vinnie',
    model: 'gpt-4o',
  },
  {
    title: 'Open AI',
    key: 'gpt_4o',
    description: '',
    model: 'gpt-4o',
    systemPrompt: true,
    max_tokens: 8191,
  },
  {
    title: 'Open AI',
    key: 'openAI_gpt_4',
    description: '',
    model: 'gpt-4',
    systemPrompt: true,
    max_tokens: 8191,
  },
  {
    title: 'Open AI',
    key: 'openAI',
    description: '',
    model: 'gpt-3.5-turbo-16k',
    systemPrompt: true,
    max_tokens: 16384,
  },
  {
    title: 'Anthropic',
    key: 'anthropic',
    description: '',
    model: 'claude-2.1',
    systemPrompt: true,
    max_tokens: 4096,
  },
  {
    title: 'Anthropic',
    key: 'anthropic_opus',
    description: '',
    model: 'claude-3-opus-20240229',
    systemPrompt: true,
    max_tokens: 4096,
  },
  {
    title: 'Anthropic',
    key: 'anthropic_sonnet',
    description: '',
    model: 'claude-3-sonnet-20240229',
    systemPrompt: true,
    max_tokens: 4096,
  },
];

export const STEP_TYPES = {
  SYSTEM: 'system',
  USER: 'user',
  ASSISTANT: 'assistant',
  OUTLAW_USER: 'outlaw',
};

export const RESPONSE_TYPES = [
  {
    key: 'block',
    title: 'Self - block',
    description: 'Single paragraph that should populate the body of this AI Block',
  },
  {
    key: 'list',
    title: 'Self - list',
    description: 'Multiple paragraphs that should populate the children of this AI Block as separate sections',
  },
  {
    key: 'variable',
    title: 'Variable',
    description: 'Data that should populate a target Variable',
  },
];

export const DATA_SOURCE_TYPES = {
  SECTIONS: 'Sections',
  VARIABLES: 'Variables',
};

const TIMELINE_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: TIMELINE_AI_PROMPT_TEXT },
  { role: STEP_TYPES.USER, content: '' },
  { role: STEP_TYPES.OUTLAW_USER, content: '' },
];

const DEMANDS_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: DEMANDS_AI_PROMPT[STEP_TYPES.SYSTEM] },
  { role: STEP_TYPES.USER, content: DEMANDS_AI_PROMPT[STEP_TYPES.USER] },
  { role: STEP_TYPES.OUTLAW_USER, content: '' },
];

const DEFAULT_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: '' },
  { role: STEP_TYPES.USER, content: '' },
  { role: STEP_TYPES.OUTLAW_USER, content: '' },
];

// Default thread configuration options, stored as metadata on the thread
// Note all values must be strings
// https://platform.openai.com/docs/api-reference/threads/createThread
export const DEFAULT_VINNIE_OPTIONS = {
  examples: 'false',
  designations: 'true',
};

//Will be pulled from DB along with the structure for v2.
export const BLOCK_TYPE = {
  DEFAULT: 'default',
  DEMANDS: 'demands',
  TIMELINE: 'timeline',
};

export const BLOCK_PROMPT = {
  default: DEFAULT_PROMPT,
  demands: DEMANDS_PROMPT,
  timeline: TIMELINE_PROMPT,
};

export const BLOCK_PROMPT_LABEL = {
  default: 'None (default)',
  demands: 'Demands AI',
  timeline: 'Timeline',
};

export const RESPONSE_OPTIONS = [1, 2, 3];

// For use with Vinnie. These will hopefully soon replace ALL the prompt config,
// as Vinnie is pre-configured with detailed instructions on how to generate each section (including firm-specific examples)
// We'll probably need to add a few additional section types as we onboard customers with more specific needs,
// but the prompts themselves are dynamic (updated in Vinnie's knowledge)
// so this way we can keep the full list of supported section types below explicit and hard-coded, but update the prompts themselves without code changes
export const PROMPT_TYPES = [
  {
    title: 'Introduction',
    key: 'INTRO',
    summary: "Overview of the letter's purpose and demand.",
  },
  {
    title: 'Statement of Facts',
    key: 'FACTS',
    summary: 'Detailed background information and events.',
  },
  {
    title: 'Liability',
    key: 'LIABILITY',
    summary: 'Legal basis for liability.',
  },
  {
    title: 'Demand',
    key: 'DEMAND',
    summary: 'Specific actions or payments being demanded.',
  },
  {
    title: 'Economic Damages',
    key: 'ECON_DMG',
    summary: 'Financial losses incurred.',
  },
  {
    title: 'Non-Economic Damages',
    key: 'NON_ECON_DMG',
    summary: 'Non-monetary losses such as pain and suffering.',
  },
  {
    title: 'Medical/Injury Chronology',
    key: 'MED_CHRON',
    summary: 'Timeline of medical treatments and injuries.',
  },
  {
    title: 'Deadline',
    key: 'DEADLINE',
    summary: 'Specific time frame for compliance.',
  },
  {
    title: 'Consequences of Non-Compliance',
    key: 'CONSEQUENCES',
    summary: 'Potential legal or practical consequences of non-compliance.',
  },
  {
    title: 'Contact Information',
    key: 'CONTACT',
    summary: "Sender's contact details for further communication.",
  },
  {
    title: 'Summary of Damages',
    key: 'SUMMARY',
    summary: 'Summarizes total economic and non-economic damages.',
  },
  {
    title: 'Closing',
    key: 'CLOSING',
    summary: 'Formal conclusion expressing hope for resolution.',
  },
];

// CJA: Since we are bringing back the OUTLAW_USER step "for now", we should also bring this
// back so the custom instructions don't get wiped out:
export const togglePromptType = (type, currentPrompt) => {
  const newPrompt = [...BLOCK_PROMPT[type]];

  // Maintain custom instructions:
  const oldOutlaw = find(currentPrompt, { role: STEP_TYPES.OUTLAW_USER });
  if (oldOutlaw) {
    const newOutlaw = find(newPrompt, { role: STEP_TYPES.OUTLAW_USER });
    if (newOutlaw) {
      newOutlaw.content = oldOutlaw.content;
    }
  }

  return newPrompt;
};

// Moved this to be a reusable function outside of the AIPrompt instance
// this way we can use it both for individual sections with Variables source,
// and for an entire deal dump (Vinnie startThread)
export const buildVariableDump = (vars) => {
  const varValues = [];
  forEach(vars, (v) => {
    let promptText = `${v.displayName || v.name}: `;
    let val = v.val;
    const emptyValText = `[${v.type}${v.name}]`;
    if (v.valueType === ValueType.TABLE) {
      if (get(v, 'val.length') > 0) {
        promptText += '\n';
        promptText += JSON.stringify(val);
      } else {
        promptText += emptyValText;
      }
    } else {
      if (v.val) promptText += v.val;
      else promptText += emptyValText;
    }

    varValues.push(promptText);
  });
  return varValues.join('\n\n');
};

//V2
//Outlaw admins will build prompts and save them at a universal level in outlaw. (They will enable prompts based on some criteria)
//Preconfiged Prompts will be selected on the template/deal for use. (Always hidden though)
//

export default class AIPrompt {
  engine = null;
  prompt = [];
  actionName = '';
  linkedSections = [];
  linkedVariables = '';
  type = null;
  responseOptions = 1;
  temperature = 1;
  responseType = 'block';
  outputVariable = null;
  dsType = DATA_SOURCE_TYPES.SECTIONS;
  autorun = false;
  lastResponse = null;
  // Normally we'd track this in an unstored component state var,
  // but because we have multiple sections with AIPrompts potentially auto-running in sequence,
  // and because a lengthy/complex prompt can take a while to complete,
  // it's safest to actually just store the running state in db while active
  isRunning = false;
  lastError = null;

  // Whether response should be streamed. Currently only supported with Vinnie
  streaming = false;
  // For Vinnie, key of one of the PROMPT_TYPES values for preset prompts
  promptTypeKey = null;

  // ES: moved to static class method to follow factory pattern
  // and removed "legacy" from name since this is actually our standard (current) only path to instantiate new AIPrompts
  static buildJSON(section, workflow, type) {
    const aiPrompt = {};
    aiPrompt.actionName = get(section, 'actionName', 'Generate');
    aiPrompt.responseOptions = 1;

    if (workflow) {
      //This means its a demand setup
      aiPrompt.prompt = workflow.serviceProviders ? cloneDeep(DEMANDS_PROMPT) : cloneDeep(DEFAULT_PROMPT);
      aiPrompt.engine = workflow.aiEngine || AI_ENGINES[0].key;
      aiPrompt.type = workflow.serviceProviders ? BLOCK_TYPE.DEMANDS : BLOCK_TYPE.DEFAULT;
    } else {
      aiPrompt.prompt = cloneDeep(DEFAULT_PROMPT);
      aiPrompt.engine = AI_ENGINES[0].key;
      aiPrompt.type = BLOCK_TYPE.DEFAULT;
    }

    //TODO: this is where we'll extend the logic and Workflow models
    //to allow admins to define Workflow-specific AIPrompt templates and apply them to blocks
    //right now we're leaving the above logic to auto-discover type from the Workflow
    //but these will be merged
    if (type) {
      const promptTemplate = BLOCK_PROMPT[type];
      if (promptTemplate) {
        aiPrompt.prompt = cloneDeep(promptTemplate);
        aiPrompt.type = type;
      }
    }

    // Legacy blocks had a simple text field for the AI prompt on the Section
    // if we find that, map it to the OUTLAW_USER step, which is user editable.
    if (section.prompt) {
      const outlawPrompt = find(aiPrompt, { role: STEP_TYPES.OUTLAW_USER });
      if (outlawPrompt) {
        outlawPrompt.content = section.prompt;
      }
    }

    // Legacy prompts used section linking to target source data
    // translate these if we find them
    if (section.children?.length > 0) {
      const linkedIDs = map(section.children, 'id');
      aiPrompt.linkedSections = linkedIDs.join('|');
    }

    return aiPrompt;
  }

  constructor(json, deal) {
    this.deal = deal;

    this.actionName = get(json, 'actionName', 'Generate');
    this.engine = find(AI_ENGINES, { key: json.engine }) || AI_ENGINES[0];
    this.prompt = get(json, 'prompt', DEFAULT_PROMPT);
    this.linkedSections = json.linkedSections ? json.linkedSections.split('|') : [];
    this.linkedVariables = get(json, 'linkedVariables', '');
    this.type = get(json, 'type', BLOCK_TYPE.DEFAULT);
    this.responseOptions = get(json, 'responseOptions', 1);
    this.temperature = get(json, 'temperature', 1);
    this.responseType = get(json, 'responseType', 'block');
    this.outputVariable = get(json, 'outputVariable') || null;
    this.dsType = get(json, 'dsType', DATA_SOURCE_TYPES.SECTIONS);
    this.autorun = get(json, 'autorun', false);
    this.lastResponse = get(json, 'lastResponse', null);
    this.isRunning = get(json, 'isRunning', false);
    this.streaming = get(json, 'streaming', false);
    this.promptTypeKey = get(json, 'promptTypeKey', null);

    this.lastError = get(json, 'lastError', null);
  }

  get json() {
    return {
      engine: this.engine.key,
      actionName: this.actionName,
      prompt: this.prompt,
      linkedSections: this.linkedSections.length > 0 ? this.linkedSections.join('|') : null,
      linkedVariables: this.linkedVariables || null,
      type: this.type,
      responseOptions: this.responseOptions,
      responseType: this.responseType,
      temperature: this.temperature,
      outputVariable: this.outputVariable || null,
      dsType: this.dsType,
      autorun: this.autorun || null,
      lastResponse: isNil(this.lastResponse) ? null : this.lastResponse,
      isRunning: this.isRunning ? true : null,
      streaming: this.streaming ? true : null,
      promptTypeKey: this.promptTypeKey || null,
      lastError: isNil(this.lastError) ? null : this.lastError,
    };
  }

  get dataSourceVariables() {
    //find variable ranges and add them as styles for flattened rendering
    let variables = [],
      matchVar;

    while ((matchVar = rxVariable.exec(this.linkedVariables)) !== null) {
      const varName = matchVar[0].slice(2, matchVar[0].length - 1).split('.')[0];
      const v = this.deal.variables[varName];
      if (v) variables.push(v);
    }

    return variables;
  }

  get dataSourceAI() {
    switch (this.dsType) {
      case DATA_SOURCE_TYPES.VARIABLES:
        return buildVariableDump(this.dataSourceVariables);
      case DATA_SOURCE_TYPES.SECTIONS:
      default:
        let table = null,
          text = null,
          source = [];

        const children = compact(map(this.linkedSections, (id) => this.deal.sections[id]));

        find(children, (child) => {
          table = find(child.variables, (v) => {
            return v.valueType === ValueType.TABLE || v.externalType === EXTERNAL_TYPES.COLLECTION;
          });

          if (table) return true;
          return false;
        });

        if (table) {
          return table.val;
        } else {
          source = children;

          // If we're pointing at a LIST, use that list's children as the source content
          if (source.length > 0 && source[0].isList && !source[0].isAI) {
            source = source[0].items;
          }

          text = map(source, (sec) => sec.currentVersion.getText('body', true, this.deal.variables)).join('\n\n');
          return text.trim() || null;
        }
    }
  }

  // This uses the same logic as dataSourceAI() to get the underlying target sections,
  // but the text output is formatted specifically for Timeline generation,
  // which requires explicit Paragraph (Section) IDs to be included for "source" linking
  get timelineSourceAI() {
    let table = null,
      text = null,
      source = [];

    const children = map(this.linkedSections, (id) => this.deal.sections[id]);

    find(children, (child) => {
      table = find(child.variables, (v) => {
        return v.valueType === ValueType.TABLE || v.externalType === EXTERNAL_TYPES.COLLECTION;
      });

      if (table) return true;
      return false;
    });

    if (table) {
      return table.val;
    } else {
      source = children;

      // If we're pointing at a LIST, use that list's children as the source content
      if (source.length > 0 && source[0].isList && !source[0].isAI) {
        source = source[0].items;
      }

      text = map(source, (sec) => {
        let para = `Paragraph ID: ${sec.id}\n`;
        para += `Paragraph Text: \n`;
        para += sec.currentVersion.getText('body', true, this.deal.variables);
        return para;
      }).join('\n\n');

      return text.trim() || null;
    }
  }

  // TODO: this works, but results in chaotic behavior where multiple blocks are autorunning at once
  // we should move this out of the individual block level (and probably to the server side)
  // to ensure that only 1 AI Block can autorun at a time, even if several are ready to go
  // Also move the "loading" state variable to be saved/stored on the AIPrompt
  // so that the user can still watch the magic sequentially while the autorun is executing
  get canAutorun() {
    const { autorun, isRunning, lastResponse, dataSourceAI } = this;

    // If autorun is currently running or has already run, stop here
    if (!autorun || isRunning || lastResponse || !dataSourceAI) return false;

    // console.log(`[${this.actionName}]`, 'Passed initial autorun check');

    if (this.dsType === DATA_SOURCE_TYPES.SECTIONS) {
      const linkedSections = map(this.linkedSections, (id) => this.deal.sections[id]);
      const todo = find(linkedSections, (sec) => !sec || !sec.content || sec.todo > 0);
      if (todo) {
        // console.log(`[${this.actionName}]`, todo);
        // console.log(`[${name}]`, 'Autorun cancelled: missing vars in linked sections');
        return false;
      }
    } else if (this.dsType === DATA_SOURCE_TYPES.VARIABLES) {
      // TODO: check that all vars are present
    }

    // NO NAME/ID specific property available for AIPrompt...
    // console.log(`[${name}]`, 'Autorun running!');
    // console.log('I SHOULD AUTORUN');

    return true;
  }

  get userPromptText() {
    return find(this.prompt, { role: STEP_TYPES.USER })?.content || '';
  }

  get outlawPromptText() {
    return find(this.prompt, { role: STEP_TYPES.OUTLAW_USER })?.content || '';
  }

  get systemPromptText() {
    return find(this.prompt, { role: STEP_TYPES.SYSTEM })?.content || '';
  }

  // The idea here is to start a parametric framework where we can fine-tune the language style based on these params
  // These settings are stored at the Team level in the AITeamConfig model. For now, the initial two parameters control:
  // - designations: whether to attempt to track existing designations, so as not to repeat them again in subsequent AI Blocks
  // - examples: whether Vinnie should look for (team-specific) example content in its knowledge and attempt to mimic it
  // - rules[]: if example training materials have been uploaded, there will be section-specific rules created
  buildVinniePrompt(aiConfig) {
    const { examples, designations, rules } = aiConfig;

    let messages = [];

    const instructions = [
      `Draft the following section according to the prompt instructions in your knowledge: [${this.promptTypeKey}]`,
    ];

    // If we have rules for this section type, insert them into the prompt here!
    let teamRules = filter(rules, {sectionType: this.promptTypeKey});
    if (examples && teamRules.length > 0) {
      instructions.push(`This firm has also provided the following drafting rules specific to this section.
If any of these rules conflict with the standard drafting instructions for this section from your knowledge, these rules should take precedence:

` + JSON.stringify(teamRules));
    }

    if (designations) {
      instructions.push(
        `Keep track of all designations (pseudonyms listed inside parentheses) established in this thread for both the Plaintiff and Defendant, and do not repeat them once they have been established.`
      );
    }

    messages.push({
      role: 'user',
      content: instructions.join('\n\n'),
    });

    return messages;
  }

  buildAPIPrompt(source) {
    let messages = [];
    let system = find(this.prompt, { role: STEP_TYPES.SYSTEM })?.content || '';
    let user = find(this.prompt, { role: STEP_TYPES.USER })?.content || '';
    let outlaw = find(this.prompt, { role: STEP_TYPES.OUTLAW_USER })?.content || '';

    // Anthropic has a separate way to "inject" the system prompt, but for OpenAI
    // it gets sent as just part of the messages array.
    if (!this.engine.key.startsWith('anthropic')) {
      if (system) {
        messages.push({ role: STEP_TYPES.SYSTEM, content: system });
      }
    }

    if (outlaw) {
      if (user) {
        user += '\n\n';
      }
      user += outlaw;
    }

    if (source) {
      if (user) {
        user += '\n\n';
      }
      user += source;
    }

    if (user) {
      messages.push({ role: STEP_TYPES.USER, content: user });
    }

    return { system, messages };
  }
}
