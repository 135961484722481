import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay, Popover } from 'react-bootstrap';

import SectionType, { HEADER_FOOTER_SUB_SECTION_TYPES } from '@core/enums/SectionType';
import DealNumberFormat from '@core/models/DealNumberFormat';
import { CONFIG_AI, CONFIG_BLOCK, CONFIG_LIST, CONFIG_REPEATER, CONFIG_TIMELINE } from '@core/models/List';
import { ALIGN } from '@core/models/SectionStyle';
import Team, { FEATURES } from '@core/models/Team';
import { INLINE_STYLES } from '@core/models/TypeStyle';
import { dt } from '@core/utils';

import { ButtonIcon, Dropdown, MenuItem } from '@components/dmp';

import NumberFormatSelector from '@components/NumberFormatSelector';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';
import { SOURCE_TYPES, SUMMARY_TYPES, TEMPLATE_FOOTER_TYPES, TEMPLATE_HEADER_TYPES } from '@routes/TemplateEditor';

const HEADER_TYPES = [
  { type: 'h1', title: 'Heading 1' },
  { type: 'h2', title: 'Heading 2' },
  { type: 'h3', title: 'Heading 3' },
  { type: 'h4', title: 'Heading 4' },
];

@autoBindMethods
export default class SectionToolbar extends Component {
  static propTypes = {
    section: PropTypes.object,
    container: PropTypes.object,
    onDelete: PropTypes.func.isRequired,
    checkFocus: PropTypes.func.isRequired,
    persistFocus: PropTypes.func.isRequired,
    propagateCommand: PropTypes.func.isRequired,
    linkingSummaryID: PropTypes.string,
    team: PropTypes.instanceOf(Team),
  };

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      // Keep a reference to the currently selected EditorState somewhere in the contract
      // This is needed so that the toolbar can react (ha!) to key-level changes,
      // e.g., showing active state on B / I / U buttons
      editorState: null,
      field: null,
    };

    this.btnDelete = createRef();
    this.btnConditions = createRef();
    this.btnNumberStyle = createRef();
  }

  updateSelection(editorState, field) {
    this.setState({ editorState, field });
  }

  canMove(dir) {
    const { section } = this.props;
    if (!section) return false;
    if ([SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(section.sectiontype)) return false;
    return section.canMove(dir, SectionType.src(section.sectiontype));
  }

  get canColumnize() {
    const { section } = this.props;
    if (!section || section.isCaption || section.isAppendix || section.isSignature) return false;

    if (section.hasConditions) return false;

    if (
      [SectionType.HEADER, SectionType.LIST, SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(
        section.sectiontype
      )
    )
      return false;

    if (section.indentLevel === 0 && _.get(section, 'sourceChildren.length') === 0 && section.hideOrder === true)
      return true;

    return false;
  }

  get isNonLeftAligned() {
    const { section } = this.props;
    if (!section) return false;
    return [ALIGN.CENTER, ALIGN.RIGHT].includes(section.alignment);
  }

  // For source sections, numbering is managed on each section
  // For summary, numbering is managed on the full block (parent level)
  get numberedSection() {
    let { section } = this.props;

    if (!section) return null;

    if (section.sectiontype === SectionType.SUMMARY) {
      while (section.indentLevel > 0) section = section.parent;
    }

    return section;
  }

  // Filter available section types so that we don't confuse users if the current template isn't setup to support all types
  // For now this is only used to hide Repeater sections for non-connected templates,
  // but can be expanded with other conditions around workflow, inbound, etc
  get availableSourceTypes() {
    const { section, team } = this.props;
    if (!section) return SOURCE_TYPES;

    return _.filter(SOURCE_TYPES, ({ test }) => typeof test !== 'function' || test({ team, deal: section.deal }));
  }

  async moveSection(direction) {
    const { section, checkFocus, persistFocus } = this.props;
    const field = checkFocus(section.id);

    await Fire.moveSection(section.layoutSection, direction, SectionType.src(section.layoutSection.sectiontype));
    if (field) persistFocus(section.id, field);
  }

  async toggleNumbering() {
    let { section, checkFocus, persistFocus } = this.props;
    const field = checkFocus(section.id);

    const numberedSection = this.numberedSection;
    const hideOrder = !numberedSection.hideOrder ? true : null;

    // If we turn off bullets, ensure we remove the custom numbering style data
    // And keep the list in sync
    if (hideOrder && section.isUnordered) {
      await Fire.saveSectionStyle(section, { numbering: null }, true);
    }

    await Fire.saveSection(numberedSection, { hideOrder });

    if (field) persistFocus(section.id, field);
  }

  async toggleColumns() {
    const { section, checkFocus, persistFocus } = this.props;
    const { style } = section;
    const field = checkFocus(section.id);

    // Toggle columns on/off
    style.columns = !style.columns;

    // If the column setting means there's now custom style, save it; otherwise null it out to default
    await Fire.saveSectionStyle(section, { columns: style.columns || null });
    if (field) persistFocus(section.id, field);
  }

  async togglePageBreak() {
    const { section, checkFocus, persistFocus } = this.props;
    const { isColumn, isCaption } = section;
    const field = checkFocus(section.id);

    //save pageBreak on caption sections container
    if (isCaption) {
      await Fire.saveSection(section.sourceParent, { pageBreak: !section.sourceParent.pageBreak });
    }
    //since each indidual column needs to be in sync with page break, update all when you update one.
    else if (isColumn) {
      const { pageBreak, sections } = section.columnContainer;
      for (let i = 0; i < sections.length; i++) {
        await Fire.saveSection(sections[i], { pageBreak: !pageBreak });
      }
    } else {
      await Fire.saveSection(section, { pageBreak: !section.pageBreak });
    }
    if (field) persistFocus(section.id, field);
  }

  // Inline style toggle commands (B/I/U) are already handled via keyboard entry,
  // so all we need to do is re-focus the section (which we'd need to do anyway)
  // and then propagate the corresponding command to the SectionEditor
  toggleInlineStyle(inlineStyle) {
    const { section, propagateCommand } = this.props;
    propagateCommand(section.id, inlineStyle.command);
  }

  async alignText(newAlign) {
    const { section, checkFocus, persistFocus } = this.props;

    const field = checkFocus(section.id);

    // Update alignment setting. We only need to store something if it differs from the theme
    // If we're switching back to default, we can null it out
    const align = section.themeAlignment === newAlign ? null : newAlign;

    await Fire.saveSectionStyle(section, { align });

    if (field) persistFocus(section.id, field);
  }

  async updateHeaderType({ type }) {
    const { section, checkFocus, persistFocus } = this.props;

    const field = checkFocus(section.id);
    await Fire.saveSection(section, { headerType: type });
    if (field) persistFocus(section.id, field);
  }

  async assignAppendix(partyID) {
    const { section, checkFocus, persistFocus } = this.props;

    const field = checkFocus(section.id);
    await Fire.saveSection(section, { assigned: partyID });
    if (field) persistFocus(section.id, field);
  }

  deleteSection() {
    this.props.onDelete();
    this.setState({ deleting: false });
  }

  canAdd(newSectionType) {
    let { section } = this.props;
    if (!section) return false;

    switch (section.sectiontype) {
      // Only APPENDIX subtype appendices can have children
      case SectionType.APPENDIX:
        if (section.appendixType === SectionType.APPENDIX) return true;
        else return SectionType.special(newSectionType);
      default:
        return true;
    }
  }

  addSectionBlock({ type: newSectionType, subType, title }) {
    let { section: addAtSection, setQueueFocus } = this.props;

    // Add a block of the specified type to the top-level of either source or summary
    const isSource = SectionType.src(newSectionType);
    const data = { sectiontype: newSectionType };

    // Ensure that we don't actually try to add more children inside of a Caption
    // (because it's always fixed to 2 children, ie 2 columns)
    // So this will reference the parent Caption section so that the new section is added after the entire Caption
    if (addAtSection.isCaption) {
      addAtSection = addAtSection.layoutSection;
    }

    if (addAtSection.isTemplateHeaderFooterSubSection) {
      addAtSection = addAtSection.sourceParent;
    }

    //setup some default content for new block if it's a summary type
    switch (newSectionType) {
      case SectionType.SUMMARY:
        data.displayname = 'Overview';
        data.content = `Summarize key terms of your ${dt}`;
        break;
      case SectionType.HEADER:
      case SectionType.CAPTION:
        data.hideOrder = true;
        data.content = null;
        break;
      case SectionType.LIST:
        // Auto-generate names for new LIST sections so that they can be referenced in Audit Log etc
        data.name = `${title} ${addAtSection.deal.lists.length + 1}`;
        if (subType === 'BLOCK') _.merge(data, CONFIG_BLOCK);
        else if (subType === 'REPEATER') _.merge(data, CONFIG_REPEATER);
        else if (subType === 'AI') _.merge(data, CONFIG_AI);
        else if (subType === 'TIMELINE') _.merge(data, CONFIG_TIMELINE);
        else _.merge(data, CONFIG_LIST);
        break;
      case SectionType.APPENDIX:
      case SectionType.SIGNATURE:
        if (subType) data.appendixType = subType;
        // No matter what, always add special (APPENDIX/SIGNATURE) sections to root, at end
        data.sourceparentid = addAtSection.deal.root.id;
        addAtSection = _.last(addAtSection.deal.root.sourceChildren);
        break;
      default:
        break;
    }

    // If we add a body section "at" a special section (SIGNATURE/APPENDIX), make it a child to avoid orphaning
    if (addAtSection.isSpecial && !SectionType.special(newSectionType)) {
      data.sourceparentid = addAtSection.id;
    }

    if ([SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(newSectionType)) {
      data.hideOrder = true;
      data.content = null;
      const subSectionType =
        title === '2-column' ? HEADER_FOOTER_SUB_SECTION_TYPES.TWO_COL : HEADER_FOOTER_SUB_SECTION_TYPES.ONE_COL;
      Fire.addHeaderFooterSection(addAtSection, newSectionType, subSectionType, (id) => setQueueFocus(id), data);
    } else {
      Fire.addSection(addAtSection, isSource, (id) => setQueueFocus(id), data);
    }
  }

  async updateNumberingStyle(s) {
    const { section, checkFocus, persistFocus } = this.props;
    const field = checkFocus(section.id);

    // Unordered styles are specific to just this section/siblings when the indent level is 1 or above
    if (s.type === 'unordered' && section.indentLevel > 0) {
      await Fire.saveSectionStyle(section, { numbering: _.pick(s, ['type', 'pre']) }, true);
    }
    // All other styles are maintained at the Deal (or Appendix) level
    // In order to enforce consistent, unbreakable numbering formats
    else {
      const newStyle = _.pick(s, ['type', 'case', 'pre', 'post', 'zeroPad', 'continueNumericPrefix']);
      let formats = [];

      // Appendices have their own independent DealStyle (which includes numbering systems)
      if (section.appendix) {
        formats = _.map(section.appendix.style.numbering, 'json');
      } else {
        formats = _.map(section.deal.style.numbering, 'json');
      }

      formats[section.indentLevel] = newStyle;
      await Fire.saveDealStyle(section, 'numbering', formats);

      // If this section previously had unordered number style, get rid of it and use the deal.style.numbeing
      if (section.isUnordered) {
        await Fire.saveSectionStyle(section, { numbering: null }, true);
      }
    }

    if (field) persistFocus(section.id, field);
  }

  render() {
    const { section, container, team } = this.props;
    const { deleting, editorState, field } = this.state;
    const { isSource, isAppendix, canPageBreak, isCaption, isColumn } = section || {};

    const isSummary = SectionType.overview(_.get(section, 'sectiontype'));
    const isTemplateHeader = SectionType.TEMPLATE_HEADER === _.get(section, 'sectiontype');
    const isTemplateFooter = SectionType.TEMPLATE_FOOTER === _.get(section, 'sectiontype');
    const canColumnize = this.canColumnize;
    const isNonLeftAligned = this.isNonLeftAligned;
    const align = _.get(section, 'alignment');
    const isHeader = _.get(section, 'sectiontype') === SectionType.HEADER;
    const hasPageBreak = isCaption
      ? _.get(section.sourceParent, 'pageBreak')
      : isColumn
      ? _.get(section.columnContainer, 'pageBreak')
      : _.get(section, 'pageBreak');

    const isBody = field === 'body';
    const currentStyle = editorState ? editorState.getCurrentInlineStyle() : null;
    const isUnnumbered = !section || section.hideOrder || !isSource;
    const isSignature = section ? section.sectiontype == 'SIGNATURE' : false;
    const nf = !section || section.hideOrder ? null : new DealNumberFormat(section.numberFormat);

    let headerType;
    if (isHeader) {
      headerType = _.find(HEADER_TYPES, { type: section.headerType });
    }

    const isEmpty = section?.isEmpty;
    const numberAlignmentOverrides = team.isFeatureActive(FEATURES.NUMBER_ALIGNMENT_OVERRIDES);
    const numberedAndNotEmpty = !isUnnumbered && !isEmpty;
    const numberedAndNoAlignmentOverride = !isUnnumbered && !numberAlignmentOverrides;

    return (
      <>
        <div className="toolbar-group">
          <Dropdown
            dmpStyle="default"
            size="small"
            disabled={!section}
            onSelect={this.addSectionBlock}
            className="insert"
            btnClassName="add-section"
            id="dd-section-type"
            data-cy="btn-add-section"
            icon="plus2"
            noCaret
            tip={{ tip: 'Add a new section', placement: 'bottom' }}
          >
            {isSource &&
              this.availableSourceTypes.map((t, idx) =>
                t.divider ? (
                  <MenuItem divider key={idx} />
                ) : t.header ? (
                  <MenuItem header key={idx}>
                    {t.header}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={idx}
                    eventKey={t}
                    icon={t.icon}
                    data-cy={`btn-add-${t.type}`}
                    disabled={!this.canAdd(t.type)}
                  >
                    {t.title}
                  </MenuItem>
                )
              )}
            {isSummary &&
              SUMMARY_TYPES.map((t) => (
                <MenuItem key={t.type} eventKey={t.type} icon={t.icon}>
                  {t.title}
                </MenuItem>
              ))}
            {isTemplateHeader &&
              TEMPLATE_HEADER_TYPES.map((t) => (
                <MenuItem key={t.title} eventKey={t} icon={t.icon}>
                  {t.title}
                </MenuItem>
              ))}
            {isTemplateFooter &&
              TEMPLATE_FOOTER_TYPES.map((t) => (
                <MenuItem key={t.title} eventKey={t} icon={t.icon}>
                  {t.title}
                </MenuItem>
              ))}
          </Dropdown>
        </div>
        {isHeader && (
          <div className="toolbar-group">
            <Dropdown
              size="small"
              onSelect={this.updateHeaderType}
              className="toolbar-item"
              id="dd-header-type"
              dmpStyle="link"
              title={_.get(headerType, 'title', 'Heading 4')}
              tip={{ tip: 'Update selected header size', placement: 'bottom' }}
              dataCyToggle="dd-header-type"
            >
              {HEADER_TYPES.map((t) => (
                <MenuItem key={t.type} eventKey={t} data-cy="header-type">
                  {t.title}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
        )}

        <div className="toolbar-group">
          {INLINE_STYLES.map((type) => (
            <TooltipButton
              key={type.label}
              tip={`Toggle ${type.style.toLowerCase()} on selected text`}
              placement="bottom"
              disabled={!section || !isBody || !editorState}
            >
              <ButtonIcon
                tool
                disabled={!section || !isBody || !editorState}
                size="default"
                icon={type.style.toLowerCase()}
                onClick={() => this.toggleInlineStyle(type)}
                className={cx({ active: currentStyle && currentStyle.has(type.style) })}
                data-cy={`btn-${type.command}`}
              />
            </TooltipButton>
          ))}
        </div>

        <div className="toolbar-group">
          <TooltipButton tip="Left-align selected section" placement="bottom" disabled={!section}>
            <ButtonIcon
              tool
              disabled={!section || isSignature}
              size="default"
              icon="alignLeft"
              onClick={() => this.alignText(ALIGN.LEFT)}
              className={cx({ active: align === ALIGN.LEFT })}
              data-cy="btn-align-start"
            />
          </TooltipButton>

          <TooltipButton
            tip={
              numberedAndNotEmpty || numberedAndNoAlignmentOverride
                ? 'Numbered section cannot be center-aligned'
                : 'Center-align selected section'
            }
            placement="bottom"
            disabled={!section}
          >
            <ButtonIcon
              tool
              disabled={!section || numberedAndNotEmpty || isSignature || numberedAndNoAlignmentOverride}
              size="default"
              icon="alignCenter"
              onClick={() => this.alignText(ALIGN.CENTER)}
              className={cx({ active: align === ALIGN.CENTER })}
              data-cy="btn-align-center"
            />
          </TooltipButton>

          <TooltipButton
            tip={
              numberedAndNotEmpty || numberedAndNoAlignmentOverride
                ? 'Numbered section cannot be right-aligned'
                : 'Right-align selected section'
            }
            placement="bottom"
            disabled={!section}
          >
            <ButtonIcon
              tool
              disabled={!section || numberedAndNotEmpty || isSignature || numberedAndNoAlignmentOverride}
              size="default"
              icon="alignRight"
              onClick={() => this.alignText(ALIGN.RIGHT)}
              className={cx({ active: align === ALIGN.RIGHT })}
              data-cy="btn-align-right"
            />
          </TooltipButton>

          <TooltipButton tip="Justify selected section" placement="bottom" disabled={!section}>
            <ButtonIcon
              tool
              disabled={!section || isSignature}
              size="default"
              icon="alignJustify"
              onClick={() => this.alignText(ALIGN.JUSTIFY)}
              className={cx({ active: align === ALIGN.JUSTIFY })}
              data-cy="btn-align-justify"
            />
          </TooltipButton>
        </div>

        <div className="toolbar-group">
          <TooltipButton
            tip="Decrease indent"
            placement="bottom"
            disabled={!section || section.isCaption || !this.canMove('left')}
          >
            <ButtonIcon
              tool
              size="default"
              icon="outdent"
              disabled={!this.canMove('left')}
              onClick={() => this.moveSection('left')}
              data-cy="btn-decrease-indent"
            />
          </TooltipButton>

          <TooltipButton
            tip="Increase indent"
            placement="bottom"
            disabled={!section || section.isCaption || !this.canMove('right')}
          >
            <ButtonIcon
              tool
              size="default"
              icon="indent"
              disabled={!this.canMove('right')}
              onClick={() => this.moveSection('right')}
              className="toolbar-item mover-right add-border-right"
              data-cy="btn-increase-indent"
            />
          </TooltipButton>
        </div>

        <div className="toolbar-group">
          <TooltipButton tip="Move section up" placement="bottom" disabled={!this.canMove('up')}>
            <ButtonIcon
              tool
              size="default"
              icon="arrowUp"
              disabled={!section || !this.canMove('up')}
              onClick={() => this.moveSection('up')}
              className="toolbar-item mover-up"
              data-cy="btn-move-sec-up"
            />
          </TooltipButton>
          <TooltipButton tip="Move section down" placement="bottom" disabled={!this.canMove('down')}>
            <ButtonIcon
              tool
              size="default"
              icon="arrowDown"
              disabled={!section || !this.canMove('down')}
              onClick={() => this.moveSection('down')}
              className="toolbar-item mover-down"
              data-cy="btn-move-sec-down"
            />
          </TooltipButton>
        </div>
        <div className="toolbar-group">
          <TooltipButton
            tip={
              isColumn
                ? 'Sections in columns cannot be numbered'
                : (isNonLeftAligned && !isEmpty) || (isNonLeftAligned && isUnnumbered && !numberAlignmentOverrides)
                ? 'Center and right aligned sections cannot be numbered'
                : 'Toggle numbering on/off'
            }
            placement="bottom"
            disabled={!section || isHeader}
          >
            <ButtonIcon
              tool
              size="default"
              icon="numbering"
              disabled={
                !section ||
                isColumn ||
                isHeader ||
                (isNonLeftAligned && !isEmpty) ||
                (isNonLeftAligned && isUnnumbered && !numberAlignmentOverrides) ||
                isAppendix ||
                isSignature ||
                section.isCaption ||
                [SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(section.sectiontype)
              }
              className={cx('btn-number', 'add-border-right', 'toolbar-item', {
                active: !_.get(this.numberedSection, 'hideOrder'),
              })}
              onClick={this.toggleNumbering}
              data-cy="btn-number"
            />
          </TooltipButton>
        </div>

        {!isSummary && (
          <div className="toolbar-group">
            <NumberFormatSelector
              id="toolbar-nf-selector"
              data-cy="toolbar-nf-selector"
              section={section}
              size="small"
              dmpStyle="link"
              disabled={isUnnumbered || isHeader}
              numberFormat={nf}
              onSelect={this.updateNumberingStyle}
            />
          </div>
        )}

        <div className="toolbar-group">
          <TooltipButton
            tip={
              canColumnize
                ? 'Toggle column layout on current section'
                : !section?.isCaption && section?.hasConditions
                ? 'Conditionals may only be added to single-column layouts unless placed inside captions'
                : 'Columns can only be applied to top-level unnumbered paragraph sections'
            }
            placement="bottom"
          >
            <ButtonIcon
              tool
              disabled={!canColumnize}
              size="default"
              icon="columns"
              onClick={this.toggleColumns}
              className={cx('btn-column', { disabled: !section, active: isColumn })}
              data-cy="btn-column"
            />
          </TooltipButton>
          <TooltipButton
            tip={
              canPageBreak && hasPageBreak
                ? 'Remove page break before this section'
                : 'Add page break before this section'
            }
            placement="bottom"
          >
            <ButtonIcon
              disabled={!canPageBreak}
              tool
              size="default"
              icon="pageBreak"
              onClick={this.togglePageBreak}
              className={cx('btn-pagebreak', { disabled: !section, active: hasPageBreak })}
              data-cy="btn-pagebreak"
            />
          </TooltipButton>
        </div>

        <div className="toolbar-group trash">
          <TooltipButton tip="Delete section" placement="bottom" disabled={!section || section.isDefault}>
            <ButtonIcon
              tool
              size="default"
              icon="trash"
              ref={this.btnDelete}
              disabled={!section || section.isDefault}
              className={cx({ active: deleting })}
              onClick={() => this.setState({ deleting: true })}
              data-cy="btn-delete-sec"
            />
          </TooltipButton>
        </div>

        {deleting && (
          <Overlay
            show={true}
            onHide={() => this.setState({ deleting: false })}
            target={this.btnDelete.current}
            placement="bottom"
            rootClose
            container={container}
          >
            <Popover className="pop-delete-section" id={`pop-${section.id}-delete`}>
              <span>Delete permanently? </span>
              <span className="confirm" data-cy="btn-confirm-delete" onClick={this.deleteSection}>
                Confirm
              </span>
            </Popover>
          </Overlay>
        )}
      </>
    );
  }
}
