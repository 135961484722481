import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { dt } from '@core/utils';

import { Button } from '@components/dmp';

import UserInfo from '@components/UserInfo';
import UserInfoModal from '@components/UserInfoModal';
import Dealer from '@root/Dealer';
import NavDash from '@root/nav/NavDash';

import Connections from './Connections';

@autoBindMethods
export default class Profile extends Component {
  static defaultProps = {
    user: null,
    account: null,
  };

  static propTypes = {
    og: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
    account: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  componentDidMount() {
    this.props.og({ title: 'Outlaw - Profile' });
  }

  render() {
    const { user, account } = this.props;
    const { editing } = this.state;

    if (!_.get(user, 'id') || !account) return null;

    return (
      <main className="dashboard">
        {/*On mobile this is already covered in global nav so only need on desktop*/}
        {!Dealer.mobile && <NavDash {...this.props} />}

        <div className="main contracts-list">
          <div className="wrapper profile">
            <div className="title-bar">
              <h1>Profile</h1>
            </div>

            <div className="setting-block profile-info">
              <div className="block-header">
                <h4>User Profile</h4>
                <span className="prompt">
                  This is the default profile info for your {dt}s on Outlaw. You can change this for each individual
                  {dt}.
                </span>
              </div>
              <div className="block-content">
                <div className="utility">
                  <Button className="btn-update" onClick={() => this.setState({ editing: true })}>
                    Update
                  </Button>
                </div>
                <UserInfo user={user} />
              </div>
            </div>
            {editing && <UserInfoModal onHide={() => this.setState({ editing: false })} show={editing} user={user} />}

            <div className="setting-block connected-logins">
              <div className="block-header">
                <h4>Connected Logins</h4>
                <span className="prompt">
                  To maximize security, Outlaw only enables login through trusted third-party providers.
                </span>
              </div>
              <div className="block-content">
                <Connections account={account} />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
