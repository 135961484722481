import _ from 'lodash';

import LensValueFilter from './LensValueFilter';

export const LENS_TYPES = [
  {
    key: 'variable',
    title: 'Variable',
    plural: 'Variables',
    tip: 'Variable Lenses have filters for comparison of values with this template',
    show: () => true,
  },
  {
    key: 'clause',
    title: 'Clause',
    plural: 'Clauses',
    tip: 'Clause Lenses have linked sections for comparison of language with this template',
    show: (template) => template.documentAI,
  },
];

export const CLAUSE_LENS_PROMPTS = [
  {
    id: 'exists',
    title: 'Exists',
    prompt: '',
    question: 'Does clause appear in document?',
    answer: (failedCheck) => {
      return failedCheck ? 'No' : 'Yes';
    },
  },
  {
    id: 'not-exist',
    title: 'Does Not Exist',
    prompt: '',
    question: 'Is clause absent from document?',
    answer: (failedCheck) => {
      return failedCheck ? 'No, clause appears in document.' : 'Yes, clause is absent from document.';
    },
  },
  {
    id: 'additional-terms',
    title: 'Has Additional Terms',
    prompt:
      'Does the text in the <records> tag have additional information that is not present in the text found in the <sample> tag? Please give your answer as a boolean value. Please give your confidence interval in your answer on a separate line formatted as a decimal from 0.00 to 1.00.',
    question: 'Matches origin clause?',
    answer: (failedCheck) => {
      return failedCheck ? 'No, clause contains additional terms.' : 'Yes, clause matches origin clause.';
    },
  },
];

export default class Lens {
  id;
  type;
  title;
  riskValue;

  //for Variable Lenses
  relatedVariable;
  valueFilters = {};

  //for Clause Lenses
  relatedSections;
  clauseFilters = {};

  constructor({
    id,
    type,
    title,
    relatedSections,
    valueFilters = {},
    relatedVariable = null,
    clauseFilters = {},
    riskValue,
  }) {
    _.assign(this, { id, type, title, relatedVariable, relatedSections });

    // Serialize/deserialize delimited properties (relatedSections)
    this.relatedSections = relatedSections ? relatedSections.split('|') : [];
    this.riskValue = parseInt(riskValue) || 0;

    _.forEach(clauseFilters, (clauseFilter) => {
      const title = _.find(CLAUSE_LENS_PROMPTS, { id: clauseFilter.id }).title;
      this.clauseFilters[clauseFilter.id] = { ...clauseFilter, title };
    });

    _.forEach(valueFilters, (lensValueFilter) => {
      this.valueFilters[lensValueFilter.id] = new LensValueFilter(lensValueFilter);
    });
  }

  get allFilters() {
    return { ...this.valueFilters, ...this.clauseFilters };
  }

  get isCreating() {
    if (this.type === 'variable') {
      return !this.relatedVariable;
    }
    if (this.type === 'clause') {
      return this.relatedSections === 0 || !this.title;
    }
  }

  get json() {
    const obj = _.pick(this, [
      'id',
      'type',
      'title',
      'relatedVariable',
      'clauseCheck',
      'clauseFilters',
      'groups',
      'valueFilters',
    ]);

    // Serialize/deserialize delimited properties (relatedSections)
    obj.relatedSections = this.relatedSections.length ? this.relatedSections.join('|') : null;
    obj.riskValue = this.riskValue.toString();
    obj.valueFilter = this.valueFilter ? this.valueFilter.json : null;
    obj.conditions = _.keys(this.conditions).length ? _.mapValues(this.conditions, 'json') : null;

    return obj;
  }
}
